import React, {useEffect, useState} from 'react';
import {Container, Grid} from "@mui/material";
import "swiper/css";
import "swiper/css/pagination";
import './styles.css';
import BigSlider from "../BigSlider/BigSlider";
import {useTranslation} from "react-i18next";
import axiosApi from "../../axiosApi";


const NewsSlider = () => {
    const {t, i18n} = useTranslation();
    const [news, setNews] = useState({});
    const [error, setError] = useState(null);

    useEffect(()=>{
        const fetchData = async () => {
            try {
                const result = await axiosApi.get('/main_page/news/');
                setNews(result.data);
            } catch (err) {
                setError(err);
            }
        };
        fetchData();
    },[])


    return (
        <Container maxWidth={false} sx={{maxWidth: "1368px"}}>
            <Grid container justifyContent={"space-between"} alignItems={"center"}>
                <Grid item>
                    <h4 className="block-title">{t('news')}</h4>
                </Grid>
                <Grid item>
                    <button className={"show-more-btn"}>{t('seeAll')}</button>
                </Grid>
            </Grid>
            <div className="blue-line news-line"></div>
            <div className={"line"}></div>

          <BigSlider id={"home"} news={news}/>


        </Container>
    );
};

export default NewsSlider;