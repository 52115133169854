import './App.css';
import Home from "./containers/Home/Home";
import {Route, Routes} from "react-router-dom";
import Layout from "./components/Layout/Layout";
import {
    commission,
    detailedEvent,
    detailedNews,
    events,
    goal,
    library,
    main,
    news,
    organization,
    resources,
    videos
} from "./paths";
import Commission from "./containers/Commission/Commission";
import News from "./containers/News/News";
import DetailedNews from "./containers/DetailedNews/DetailedNews";
import Events from "./containers/Events/Events";
import DetailedEvent from "./containers/DetailedEvent/DetailedEvent";
import Library from "./containers/Library/Library";
import VideoPage from "./containers/VideoPage/VideoPage";
import Resources from "./containers/Resources/Resources";
import Goal from "./containers/Goal/Goal";
import DetailedOrganization from "./containers/DetailedOrganization/DetailedOrganization";
import DetailedGovernment from "./containers/DetailedGovernment/DetailedGovernment";

function App() {
    return (
        <Layout>
            <Routes>
                <Route path={main} element={<Home/>}/>
                <Route path={commission} element={<Commission/>}/>
                <Route path={news} element={<News/>}/>
                <Route path={'/news/:id'} element={<DetailedNews/>}/>
                <Route path={events} element={<Events/>}/>
                <Route path={library} element={<Library/>}/>
                <Route path={videos} element={<VideoPage/>}/>
                <Route path={resources} element={<Resources/>}/>
                <Route path={'/goals/:id'} element={<Goal/>}/>
                <Route path={'/organizations/:id'} element={<DetailedOrganization/>}/>
                <Route path={'/government/:id'} element={<DetailedGovernment/>}/>
                <Route path={'/events/:id'} element={<DetailedEvent/>}/>
            </Routes>
        </Layout>
    );
}

export default App;
