import React from 'react';
import DatePicker from "react-datepicker";
import ru from "date-fns/locale/ru";
import en from "date-fns/locale/en-US";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {setDate} from "../../store/actions/newsActions";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import {Tooltip} from "@mui/material";
import './styles.css'

const Calendar = () => {
    const {t,i18n} = useTranslation();
    const dispatch = useDispatch();
    const selectedDate = useSelector(state => state.news.date);

    const clearDate = () => {
        dispatch(setDate(null)); // set selected date to null
    }

    return (
        <div className={"calendar-container"}>
            <DatePicker
                selected={selectedDate}
                onChange={date => dispatch(setDate(date))}
                inline
                open
                locale={i18n.language === 'en' ? en : ru}
                showSixWeeks={false}
                calendarStartDay={1}
            />
            <p className='note-calendar'>*{t('calendarNote')}

                <Tooltip title={t('reset')} placement="right">
                    <div onClick={()=>clearDate()}
                         style={{display:"flex", justifyContent:"right", position:"absolute", bottom:"-21px", right:"18px"}}
                    ><RestartAltIcon sx={{color:"#aeaeae"}}/>
                    </div>
                </Tooltip>

            </p>

        </div>

    );
};

export default Calendar;