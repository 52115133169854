import React from 'react';
import './styles.css'
import news from '../../assets/images/news.jpeg'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {detailedNews} from "../../paths";
import {useTranslation} from "react-i18next";
import {mediaUrl} from "../../config";
import DOMPurify from "dompurify";
import {Link} from "react-router-dom";

const NewsSingleCard = ({news}) => {
    const {t,i18n} = useTranslation();
    return (
        <div className={"news-single-box"}
        style={{ backgroundImage: `url(${mediaUrl+news.news_slider_image[0]?.image})`}}
        >

            <p className="news-date">{news.date}</p>

            {i18n.language === 'ky' && (
                <p className="news-title">{news.title_ky}</p>
            )}
            {i18n.language === 'ru' && (
                <p className="news-title">{news.title_ru}</p>
            )}
            {i18n.language === 'en' && (
                <p className="news-title">{news.title_en}</p>
            )}


            {i18n.language === 'ky' && (
                <div className={"news-subtitle"} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(news.text_ky)}}>
                </div>
            )}
            {i18n.language === 'ru' && (
                <div className={"news-subtitle"} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(news.text_ru)}}>
                </div>
            )}
            {i18n.language === 'en' && (
                <div className={"news-subtitle"} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(news.text_en)}}>
                </div>
            )}

            <div className={"more-btn-div"}>
                <Link to={'/news/'+news.id}>{t('more')} </Link> <div className={"more-btn-line"}></div><ArrowForwardIosIcon sx={{color:"white", fontSize:"22px",}}/>
            </div>


        </div>
    );
};

export default NewsSingleCard;