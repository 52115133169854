import React, {useEffect, useState} from 'react';
import {Container, Grid, MenuItem, Pagination, Select, useMediaQuery} from "@mui/material";
import Calendar from "../../components/Calendar/Calendar";
import SinglePlayer from "../../components/SinglePlayer/SInglePlayer";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {fetchGoalsRequest} from "../../store/actions/goalsActions";
import axiosApi from "../../axiosApi";
import NotFound from "../../components/NotFound/NotFound";
import Preloader from "../../components/Preloader/Preloader";
import {resetDate} from "../../store/actions/newsActions";
import {useMemo} from "react";
import {Link} from "react-router-dom";

const VideoPage = () => {
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const [aim, setAim] = useState(1);
    const [aimId, setAimId] = useState(1);
    const [showModal, setShowModal] = useState(false);
    const mobileScreen = useMediaQuery('(max-width:504px)');

    const goals = useSelector(state => state.goals.goals);
    const date = useSelector(state => state.news.date);


    const [loading, setLoading] = useState(false);
    const [videos, setVideos] = useState([]);
    const [error, setError] = useState(null);
    const [pageCount, setPageCount] = useState(0)
    const [count, setCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        dispatch(fetchGoalsRequest());

        return () => {
            dispatch(resetDate());
        }

    }, []);


    const getData = async (page) => {
        setCurrentPage(page)
        if (date) {
            try {
                const selectedDate = new Date(date);
                const day = selectedDate.getDate();
                const month = selectedDate.getMonth() + 1;
                const year = selectedDate.getFullYear();
                const result = await axiosApi.get('/videos/videos-info/?page=' + page + '&goal_id=' + aimId + '&date=' + year + '-' + month + '-' + day);
                setVideos(result.data && result.data.results);
                setPageCount(Math.ceil(result.data.count / 8))
                setCount(result.data.count)
                setLoading(false);

            } catch (error) {
                setError(error);
                setLoading(false);
            }
        } else {
            try {
                const result = await axiosApi.get('/videos/videos-info/?page=' + page + '&goal_id=' + aimId);
                setVideos(result.data && result.data.results);
                setPageCount(Math.ceil(result.data.count / 8))
                setCount(result.data.count)
                setLoading(false);
            } catch (e) {
                setError(e);
                setLoading(false);
            }
        }
    }



    const getDataSearch = async (page) => {
        setCurrentPage(page)
        if (date) {
            try {
                const selectedDate = new Date(date);
                const day = selectedDate.getDate();
                const month = selectedDate.getMonth() + 1;
                const year = selectedDate.getFullYear();
                const result = await axiosApi.get('/videos/videos-info/?page=' + page + '&goal_id=' + aimId + '&date=' + year + '-' + month + '-' + day);
                setVideos(result.data && result.data.results);
                setPageCount(Math.ceil(result.data.count / 8))
                setCount(result.data.count)
                setLoading(false);

            } catch (error) {
                setError(error);
                setLoading(false);
            }
        } else {
            try {
                const result = await axiosApi.get('/videos/videos-info/?page=' + page + '&goal_id=' + aimId);
                setVideos(result.data && result.data.results);
                setPageCount(Math.ceil(result.data.count / 8))
                setCount(result.data.count)
                setLoading(false);
            } catch (e) {
                setError(e);
                setLoading(false);
            }
        }
    }


    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);

            if (date) {
                const selectedDate = new Date(date);
                const year = selectedDate.getFullYear();
                const month = selectedDate.getMonth() + 1;
                const day = selectedDate.getDate();

                console.log(year+'-'+month+'-'+day);
                try {
                    const result = await axiosApi.get('/videos/videos-info/?page=1&goal_id=' + aimId + '&date=' + year + '-' + month + '-' + day);
                    setVideos(result.data && result.data.results);
                    setPageCount(Math.ceil(result.data.count / 8))
                    setCount(result.data.count)
                    setLoading(false);

                } catch (error) {
                    setError(error);
                    setLoading(false);
                }
            } else {
                try {
                    const result = await axiosApi.get('/videos/videos-info/?page=1&goal_id=' + aimId);

                    setVideos(result.data && result.data.results);
                    setPageCount(Math.ceil(result.data.count / 8))
                    setCount(result.data.count)
                    setLoading(false);

                } catch (error) {
                    setError(error);
                    setLoading(false);
                }
            }
        }

        fetchData();

    }, [date]);




    const breadcrumbs = useMemo(() => {
        return (
            <p className="path-text">
                <Link  to={'/'}>{t('main')}</Link> /
                <Link to={'/videos'}> {t('videos')}</Link> /

            </p>
        )

    }, [i18n.language])



    return (
        <Container maxWidth={false} sx={{maxWidth: "1368px"}}>
            <Grid container justifyContent={"space-between"} alignItems={"center"}
                  sx={{marginTop: mobileScreen ? "20px" : "52px"}}>
                <Grid item>
                    <h4 className="block-title">{t('videos')}</h4>
                </Grid>
                {/*<Grid item>*/}
                {/*    <button className={"show-more-btn"}>Показать все</button>*/}
                {/*</Grid>*/}

            </Grid>
            <div className="blue-line lib-line"></div>
            <div className={"line"}></div>

            {breadcrumbs}
            <p className="filter-title">{t('filter')}</p>
            <Grid container justifyContent={"space-between"} alignItems="center" sx={{marginBottom: '66px'}}>
                <Grid item xs={12} sm={8.9} sx={{marginBottom: "10px"}}>
                    <Select
                        labelId="demo-select-small"
                        id="demo-select-small"
                        className={"aim-select"}
                        value={aim}
                        label={"format"}
                        onChange={(e) => {
                            const value = isNaN(e.target.value) ? "" : parseInt(e.target.value, 10);
                            setAim(value);
                        }}
                        IconComponent={KeyboardArrowDownIcon}
                        sx={{
                            boxShadow: 'none',
                            fontFamily: "Montserrat, sans-serif",
                            backgroundColor: "rgba(255,255,255,0.7)",
                            '.MuiSelect-select': {color: '#55606E',},
                            borderRadius: "0px",
                            '.MuiOutlinedInput-notchedOutline': {border: 0},
                            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {border: 0},
                            '.MuiSelect-icon': {
                                color: '#006EB5',
                                right: "9px",
                                top: "25%",
                            },

                        }}


                        inputProps={{
                            MenuProps: {
                                disableScrollLock: true,
                                sx: {
                                    borderRadius: "0px",
                                    position: "absolute",
                                    top: "0",
                                    left: "0",
                                    '& .MuiMenuItem-root': {
                                        fontFamily: 'Montserrat, sans-serif',
                                        color: "#55606E",
                                        whitespace: "pre-wrap"
                                    },
                                    '& .MuiMenuItem-root:hover': {
                                        backgroundColor: "#d1e6ee",
                                    },
                                    '& .MuiList-root': {
                                        padding: 0,
                                    }
                                }
                            }
                        }}

                    >

                        {goals.length > 0 && goals.map((goal) => (
                            <MenuItem
                                value={goal.goal_number}
                                id={goal.id}
                                onClick={() => setAimId(goal.id)}
                                key={goal.id}
                            >

                                {i18n.language === 'ky' && (
                                    <p className={"goal"}><span
                                        style={{
                                            color: "#00A4DC",
                                            marginRight: "10px"
                                        }}>{goal.goal_number} - Максат: </span>
                                        {goal.title_ky}
                                    </p>
                                )}
                                {i18n.language === 'ru' && (
                                    <p className={"goal"}><span
                                        style={{
                                            color: "#00A4DC",
                                            marginRight: "10px"
                                        }}>Цель {goal.goal_number}: </span>
                                        {goal.title_ru}
                                    </p>
                                )}
                                {i18n.language === 'en' && (
                                    <p className={"goal"}><span
                                        style={{
                                            color: "#00A4DC",
                                            marginRight: "10px"
                                        }}>Goal {goal.goal_number}: </span>
                                        {goal.title_en}
                                    </p>
                                )}

                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
                <Grid item container xs={12} sm={3} justifyContent={{xs: "center", md: "right"}}
                      sx={{marginBottom: "10px"}}>
                    <button className={'blue-main-btn search-btn'} onClick={()=>getDataSearch(1)}>{t('search')}</button>
                </Grid>
            </Grid>


            {loading ? (
                <Preloader/>
            ) : (
                <>
                    {videos.length === 0 ? (
                        <Grid container justifyContent={"space-between"} sx={{margin: "32px 0"}}>
                            <Grid item md={3.2}>
                                <Calendar/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <NotFound/>
                            </Grid>
                        </Grid>
                    ) : (
                        <>
                            <Grid container justifyContent={"space-between"} sx={{margin: "20px 0 25px"}}
                                  alignItems={"center"}>
                                <Grid item xs={12} sm={5.8} md={3.85}>
                                    <Calendar/>
                                </Grid>

                                <Grid item xs={12} sm={5.8} md={3.85}>
                                    {videos[0] && (
                                        <SinglePlayer video={videos[0]}/>
                                    )}

                                </Grid>
                                <Grid item xs={12} sm={12} md={3.85}>
                                    {videos[1] && (
                                        <SinglePlayer video={videos[1]}/>
                                    )}
                                </Grid>
                            </Grid>

                            <Grid container justifyContent={"space-between"} sx={{margin: "0 0 5px"}}>
                                <Grid item xs={12} sm={5.8} md={3.85}>
                                    {videos[2] && (
                                        <SinglePlayer video={videos[2]}/>
                                    )}
                                </Grid>
                                <Grid item xs={12} sm={5.8} md={3.85}>
                                    {videos[3] && (
                                        <SinglePlayer video={videos[3]}/>
                                    )}
                                </Grid>
                                <Grid item xs={12} sm={12} md={3.85}>
                                    {videos[4] && (
                                        <SinglePlayer video={videos[4]}/>
                                    )}
                                </Grid>
                            </Grid>

                            <Grid container justifyContent={"space-between"} sx={{margin: "0 0 5px"}}>
                                <Grid item xs={12} sm={5.8} md={3.85}>
                                    {videos[5] && (
                                        <SinglePlayer video={videos[5]}/>
                                    )}
                                </Grid>
                                <Grid item xs={12} sm={5.8} md={3.85}>
                                    {videos[6] && (
                                        <SinglePlayer video={videos[6]}/>
                                    )}
                                </Grid>
                                <Grid item xs={12} sm={12} md={3.85}>
                                    {videos[7] && (
                                        <SinglePlayer video={videos[7]}/>
                                    )}
                                </Grid>
                            </Grid>
                        </>

                    )}

                    {count > 8 && (
                        <Grid container justifyContent={"right"} sx={{margin: '20px 0'}}>

                            <Pagination
                                count={pageCount}
                                onChange={(event, page) => getData(page)}
                                page={currentPage}
                                sx={{
                                    '& .MuiPaginationItem-root': {
                                        fontWeight: "600",
                                        backgroundColor: "#F2F6F9",
                                        borderRadius: "0",
                                        color: "#55606E",
                                        height: "38px"
                                    },
                                    '& .Mui-selected': {
                                        border: ' 1px solid #00A4DC',
                                        backgroundColor: "transparent",
                                        color: "#00A4DC",
                                        borderRadius: "0",
                                        padding: "5px 0"
                                    },
                                    '& .MuiPaginationItem-root:hover': {
                                        border: ' 1px solid #00A4DC',
                                        color: "#00A4DC",
                                    },
                                    '.MuiPagination-ul': {
                                        '& .MuiPaginationItem-icon': {
                                            color: '#00A4DC',
                                        },
                                        '& .MuiPaginationItem-icon:hover': {
                                            color: '#00A4DC',
                                        },
                                    },
                                }}
                            />
                        </Grid>
                    )}
                </>

            )}

        </Container>
    );
};

export default VideoPage;