import React, {useState} from 'react';
import {Container, Grid, useMediaQuery} from "@mui/material";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation, Pagination} from "swiper";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ChartSingleCard from "../ChartSingleCard/ChartSingleCard";
import './styles.css'
import Map from "../Map/Map";
import {useEffect} from "react";
import axiosApi from "../../axiosApi";
import {useTranslation} from "react-i18next";

const PlatformSlider = () => {
    const isSmallScreen = useMediaQuery('(max-width:750px)');
const {t, i18n} = useTranslation();
    const [eventsStat, setEventStat] = useState([]);
    const [newsStat, setNewsStat] = useState([]);
    const [libraryStat, setLibraryStat] = useState([]);


    useEffect(()=>{
        const fetchData = async () => {
            try {
                const resultEvent= await axiosApi.get('/main_page/events_stats/');
                const resultNews= await axiosApi.get('/main_page/news_stats/');
                const resultLibrary= await axiosApi.get('/main_page/library_stats/');
                setEventStat(resultEvent.data && resultEvent.data.sort((a, b) => a.goal_number - b.goal_number));
                setNewsStat(resultNews.data && resultNews.data.sort((a,b)=>a.goal_number - b.goal_number));
                setLibraryStat(resultLibrary.data && resultLibrary.data.sort((a,b)=>a.goal_number - b.goal_number));

            } catch (err) {
                console.log(err)
            }
        };
        fetchData();
    },[])



    return (
        <Container maxWidth={false} sx={{maxWidth: "1368px"}}>
            <div className={"lib-home"}>
                <Grid container justifyContent={"space-between"} alignItems={"center"}>
                    <Grid item>
                        <h4 className="block-title">{t('platformStatistics')}</h4>
                    </Grid>
                    {/*<Grid item>*/}
                    {/*    <button className={"show-more-btn"}>{t('more')}</button>*/}
                    {/*</Grid>*/}
                </Grid>
                <div className="blue-line "></div>
                <div className={"line"}></div>
            </div>



            {isSmallScreen ? (
                <div className={"platform-swiper-container"}>
                    <Swiper
                        pagination={{
                            el: `#platform-big-swiper`,
                            type: "bullets",
                            clickable: false,
                        }}
                        modules={[Navigation, Pagination]}
                        className="mySwiper"
                        slidesPerView={1}
                        spaceBetween={10}
                        navigation={{
                            nextEl: ".swiper-button-next-platform",
                            prevEl: ".swiper-button-prev-platform",
                            clickable:true,
                        }}
                    >
                        <SwiperSlide>
                            <Grid container justifyContent={"space-between"}>
                                {newsStat.length > 0 && (
                                    <Grid item xs={12} sm={12} md={12}>
                                        <ChartSingleCard type={'news'} dataInfo={newsStat}/>
                                    </Grid>
                                )}
                            </Grid>
                        </SwiperSlide>
                            <SwiperSlide>
                                <Grid container justifyContent={"space-between"}>
                                    {eventsStat.length > 0 && (
                                        <Grid item xs={12} sm={12} md={12}>
                                            <ChartSingleCard type={"events"} dataInfo={eventsStat}/>
                                        </Grid>
                                    )}
                                </Grid>
                            </SwiperSlide>

                        <SwiperSlide>
                            <Grid container justifyContent={"space-between"}>
                                {libraryStat.length > 0 && (
                                    <Grid item xs={12}>
                                        <ChartSingleCard type={"library"} dataInfo={libraryStat}/>
                                    </Grid>
                                )}
                            </Grid>
                        </SwiperSlide>
                    </Swiper>


                    <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", margin:"15px 0"}}>
                        <div className="swiper-pagination-container">
                            <div className="swiper-pagination"  id={'platform-big-swiper'} style={{textAlign: "left"}}></div>
                        </div>

                    </div>
                    <div className={"navigation-btns-p"}>
                        <button className="swiper-button-prev-platform"  style={{marginRight:"16px"}}><ArrowBackIosIcon sx={{marginLeft:"8px"}}/></button>
                        <button className="swiper-button-next-platform"><ArrowForwardIosIcon /></button>
                    </div>


                </div>
            ):(
                <div className={"platform-swiper-container"}>
                    <Swiper
                        pagination={{
                            el: `#platform-big-swiper`,
                            type: "bullets",
                            clickable: false,
                        }}
                        modules={[Navigation, Pagination]}
                        className="mySwiper"
                        slidesPerView={1}
                        spaceBetween={10}
                        navigation={{
                            nextEl: ".swiper-button-next-platform",
                            prevEl: ".swiper-button-prev-platform",
                            clickable:true,
                        }}
                    >
                        <SwiperSlide>
                            <Grid container justifyContent={"space-between"}>
                                {newsStat.length > 0 && (
                                    <Grid item xs={12} sm={5.95}>
                                        <ChartSingleCard type={'news'} dataInfo={newsStat}/>
                                    </Grid>
                                )}

                                {eventsStat.length > 0 && (
                                    <Grid item xs={12} sm={5.95}>
                                        <ChartSingleCard type={"events"} dataInfo={eventsStat}/>
                                    </Grid>
                                )}
                            </Grid>

                        </SwiperSlide>
                        <SwiperSlide>
                            <Grid container justifyContent={"space-between"}>
                                {libraryStat.length > 0 && (
                                    <Grid item xs={12}>
                                        <ChartSingleCard type={"library"} dataInfo={libraryStat}/>
                                    </Grid>
                                )}
                            </Grid>
                        </SwiperSlide>
                        <SwiperSlide>
                            <Grid container justifyContent={"center"}>
                                <Grid item xs={12} sm={12}>
                                    <Map/>
                                </Grid>
                            </Grid>
                        </SwiperSlide>
                    </Swiper>


                    <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", margin:"15px 0"}}>
                        <div className="swiper-pagination-container">
                            <div className="swiper-pagination"  id={'platform-big-swiper'} style={{textAlign: "left"}}></div>
                        </div>

                    </div>
                    <div className={"navigation-btns-p"}>
                        <button className="swiper-button-prev-platform"  style={{marginRight:"16px"}}><ArrowBackIosIcon sx={{marginLeft:"8px"}}/></button>
                        <button className="swiper-button-next-platform"><ArrowForwardIosIcon /></button>
                    </div>


                </div>

            )}
            {isSmallScreen && (
                <Grid container justifyContent={"center"} sx={{height:"400px"}}>
                        <Map/>
                </Grid>
            )}


        </Container>
    );
};

export default PlatformSlider;