import React, {useState} from 'react';
import {Container, Grid, useMediaQuery} from "@mui/material";
import AimsComponent from "../../components/AimsComponent/AimsComponent";
import Icon1 from "../../assets/images/1icon.png";
import MiniSlider from "../../components/MiniSlider/MiniSlider";
import ShareComponent from "../../components/ShareComponent/ShareComponent";
import shareIcon from "../../assets/images/share.svg";
import pdf from "../../assets/images/pdf-icon.svg";
import Preloader from "../../components/Preloader/Preloader";
import {Link, useParams} from "react-router-dom";
import {useEffect} from "react";
import axiosApi from "../../axiosApi";
import {useTranslation} from "react-i18next";
import {useMemo} from "react";
import axios from "axios";
import fileDownload from "js-file-download";
import {mediaUrl} from "../../config";
import DOMPurify from "dompurify";
import shareArrow from "../../assets/images/Arrow.svg";

const DetailedOrganization = () => {
    const mobileScreen = useMediaQuery('(max-width:504px)');
    const {id} = useParams();
    const {t, i18n} = useTranslation();

    const [shareShow, setShareShow] = useState(false);
    const [organization, setOrganization] = useState(null);
    const [loading, setLoading] = useState(false);

    console.log(organization)

    useEffect(()=>{
        const fetchData = async () => {
            setLoading(true);
            try {
                const result = await axiosApi.get('/goals/int_article/' + id + '/');
                setOrganization(result.data);
                setLoading(false)
            } catch (err) {
                setLoading(false)
            }
        };
        fetchData();
    },[id])


    const breadcrumbs = useMemo(() => {
        return (
            <>
                {organization && (
                    <>
                        <p className="path-text">
                            <Link to={'/'}>{t('main')}</Link> /

                            <Link
                                to={'/goals/' + organization.goal_id.id}> {t('goal')} {organization.goal_id.goal_number} </Link>

                            {i18n.language === 'ky' && (
                                <span> / {organization.title_ky}</span>
                            )}

                            {i18n.language === 'ru' && (
                                <span> / {organization.title_ru}</span>
                            )}

                            {i18n.language === 'en' && (
                                <span> / {organization.title_en}</span>
                            )}

                        </p>
                    </>
                )}

            </>

        )

    }, [i18n.language, id, organization])


    const handleDownload = (url) => {
        const arr = url.split("/");
        const filename = arr[arr.length - 1];
        axios.get(url, {
            responseType: 'blob',
        })
            .then((res) => {
                fileDownload(res.data, filename)
            })

    }




    return (
        <Container maxWidth={false} sx={{maxWidth: "1368px"}}>
            {loading ? (
                <Preloader/>
            ) : (
                <>
                    {organization && (
                        <>
                            <Grid container justifyContent={"space-between"} alignItems={"center"}
                                  sx={{marginTop: mobileScreen ? "20px" : "52px"}}>
                                <Grid item>
                                    {i18n.language === 'ky' && (
                                        <h4 className="block-title">{organization.int_title_ky}</h4>
                                    )}
                                    {i18n.language === 'ru' && (
                                        <h4 className="block-title">{organization.int_title_ru}</h4>
                                    )}
                                    {i18n.language === 'en' && (
                                        <h4 className="block-title">{organization.int_title_en}</h4>
                                    )}

                                </Grid>
                                {/*<Grid item>*/}
                                {/*    <button className={"show-more-btn"}>Показать все</button>*/}
                                {/*</Grid>*/}

                            </Grid>
                            <div className="blue-line"></div>
                            <div className={"line"}></div>

                            {breadcrumbs}


                            <Grid container>
                                <div><img src={mediaUrl+organization.goal_id.little_icon} alt="goal" className="goal-icon"/></div>
                            </Grid>

                            {i18n.language === 'ky' && (
                                <h3 className={'commission-title'}>{organization.title_ky}</h3>
                            )}
                            {i18n.language === 'ru' && (
                                <h3 className={'commission-title'}>{organization.title_ru}</h3>
                            )}
                            {i18n.language === 'en' && (
                                <h3 className={'commission-title'}>{organization.title_en}</h3>
                            )}

                            <div className="commission-inner">
                                <div className="slider-float-box">
                                    <MiniSlider id={"news"} pictures={organization.international_agency_images}/>
                                </div>

                                {i18n.language === 'ky' && (
                                    <div
                                        dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(organization.text_ky)}}></div>
                                )}
                                {i18n.language === 'ru' && (
                                    <div
                                        dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(organization.text_ru)}}></div>
                                )}
                                {i18n.language === 'en' && (
                                    <div
                                        dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(organization.text_en)}}></div>
                                )}


                                {organization.graph_embed_url && (
                                    <div className={"wrapper"}>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: organization.graph_embed_url
                                            }} className={"embed-responsive"}></div>
                                    </div>
                                )
                                }


                            </div>
                            {shareShow && (
                                <ShareComponent onClose={() => setShareShow(false)}/>
                            )}
                            <Grid container justifyContent={"space-between"} alignItems={"center"}
                                  sx={{marginBottom: "81px"}}>

                                <Grid item className="btns-parent" sx={{marginBottom: "10px"}}>
                                    <div style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "left",
                                        marginRight: "60px"
                                    }} className={"share-btn-div"}>
                                        <button
                                            className="blue-btn"
                                            onClick={() => setShareShow(!shareShow)}
                                        >{t('share')}</button>

                                        <div style={{position: "relative", display: "flex", alignItems: "end"}}>
                                            <img src={shareIcon} alt="shareIcon" className={"share-icon"}/> <img
                                            src={shareArrow} alt="shareIcon" className={"share-arrow"}/>
                                        </div>
                                    </div>


                                    <button className="blue-btn"
                                            onClick={() => handleDownload(mediaUrl + organization.file)}>{t('download')} <img
                                        src={pdf} alt="pdf icon"/></button>
                                </Grid>
                                <Grid item>
                                    <p className="date-text">{organization.date}</p>
                                </Grid>

                            </Grid>
                        </>
                    )}

                </>
            )}

        </Container>
    );
};

export default DetailedOrganization;