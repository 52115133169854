import React, {useRef, useState} from 'react';
import './styles.css';
import {Container, Grid} from "@mui/material";
import {Formik} from 'formik'
import folder from '../../assets/images/folder.svg'
import upload from '../../assets/images/clarity_upload-cloud-line.svg'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {useTranslation} from "react-i18next";
import {apiURL} from "../../config";

const ContactForm = () => {

    const {t} = useTranslation();

    const [contact, setContact] = useState({
        name: "",
        email: "",
        text: "",
    })

    const [agree, setAgree] = useState(false)


    const [files, setFiles] = useState(null);
    const inputRef = useRef();


    console.log(files)
    const handleSubmit = async (values, {setSubmitting}) => {
        if(agree){
            const formData = new FormData();
            formData.append('name', values.name);
            formData.append('email', values.email);
            formData.append('text', values.text);
            if (files) {
                formData.append('files', files[0]);
            }

            try {
                const response = await fetch(apiURL+'/about_us/inquiries/', {
                    method: 'POST',
                    body: formData,
                });
                const data = await response.json();
                console.log(data);
            } catch (error) {
                console.error(error);
            }
        }else {
            console.log("agree")
        }

        setSubmitting(false)
    };


    const handleCheckbox = () => {
       setAgree(!agree)
    }

    const handleDragOver = (e) => {
        e.preventDefault()
    }

    const handleDrop = (e) => {
        e.preventDefault();
        setFiles(e.dataTransfer.files)
    }


    return (
        <div className="contact-form">
            <Container maxWidth={"md"}>
                <h5 style={{margin:"10px 0"}}>{t("contactCommision")}</h5>
                <div className="blue-line"></div>
                <div className='line'></div>

                <p className={'contact-form-p'}>{t('fill')}</p>

                <Formik
                    initialValues={contact}
                    onSubmit={handleSubmit}
                    // validationSchema={validationSchema}
                >
                    {({handleSubmit, handleChange, values, errors, touched}) => (
                        <form onSubmit={handleSubmit}>

                            <input
                                type={"text"}
                                name="name"
                                onChange={handleChange}
                                value={values.name}
                                className={errors.name && touched.name ? "contact-input" : "contact-input"}
                                placeholder={t('nameLastname')}
                            />
                            {/*{errors.name && touched.name ? (*/}
                            {/*    <p className="required">{errors.name}</p>*/}
                            {/*) : null}*/}

                            <input type={"text"}
                                   name="email"
                                   onChange={handleChange}
                                   value={values.email}
                                   className={errors.name && touched.name ? "contact-input" : "contact-input"}
                                   placeholder={t('email')}
                            />
                            {/*{errors.email && touched.email ? (*/}
                            {/*    <p className="required">{errors.email}</p>*/}
                            {/*) : null}*/}

                            <textarea
                                placeholder={t('message')}
                                className={"contact-input-textarea"}
                                name={"text"}
                                value={values.text}
                                onChange={handleChange}

                            />

                            <div>
                                <div className={"file-icon"}>
                                    <div className={"file-icon-wrapper"}>
                                        <img src={folder} alt="folder"/>
                                    </div>
                                    <p>{t('upload')}</p>
                                </div>

                                <p className={"file-p"}>{t('drop')}</p>

                                    <div className={'file-upload'}

                                         onDragOver={handleDragOver}
                                         onDrop={handleDrop}
                                    >
                                        <img src={upload} alt="upload"/>
                                        <p className='extension'>.PDF .JPG .PNG</p>
                                        <p className="file-upload-p">{t('alsoHere')}
                                        </p>

                                        {files && (
                                                <p className="uploaded">{files[0].name}</p>
                                        )}

                                        <input type="file"
                                               onChange={(e) => setFiles(e.target.files)}
                                               hidden
                                               ref={inputRef}
                                        />
                                        <button type={"button"} style={{margin:"10px 0", textTransform:"lowercase"}} onClick={() => inputRef.current.click()}>
                                            {t('upload')}
                                        </button>
                                    </div>


                            </div>


                            <Grid container sx={{marginTop: "50px"}} justifyContent={"space-between"}
                                  alignItems={"center"}>
                                <Grid item xs={12} sm={6}>
                                    <div style={{display: "flex", alignItems: "center"}}>

                                        <input type="checkbox" id={"agree"} className={"checkbox"}
                                               onChange={handleCheckbox}/>

                                        {agree ? (
                                            <CheckBoxIcon sx={{color: "#00A4DC"}}/>
                                        ) : (
                                            <CheckBoxOutlineBlankIcon sx={{color: "#00A4DC"}}/>
                                        )}
                                        <label htmlFor={"agree"} className={"agree-label"}>
                                            {t('agree')}
                                        </label>
                                    </div>
                                </Grid>
                                <Grid item>
                                    <button type="submit" className="blue-main-btn">{t('send')}</button>
                                </Grid>
                            </Grid>


                        </form>
                    )}
                </Formik>
            </Container>
        </div>
    );
};

export default ContactForm;