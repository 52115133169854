import React, {useEffect, useState} from 'react';
import {Container, Grid} from "@mui/material";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation, Pagination} from "swiper";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import './styles.css'
import {useTranslation} from "react-i18next";
import axiosApi from "../../axiosApi";
import Script from '@gumgum/react-script-tag';
import {CategoryScale, Legend, LinearScale, LineElement, PointElement, Tooltip,} from 'chart.js'
import {Bar, Line} from "react-chartjs-2";
import {Chart as ChartJS} from 'chart.js/auto'
import ChartFromApi from "../ChartFromApi/ChartFromApi";


ChartJS.register(
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Legend,
    Tooltip,
)

const GoalsSlider = () => {
    const {t, i18n} = useTranslation();
    const [firstChart, setFirstChart] = useState(null);
    const [secondChart, setSecondChart] = useState(null);
    const [thirdChart, setThirdChart] = useState(null);
    const [forthChart, setForthChart] = useState(null);
    const [loading, setLoading] = useState(false);
    const [chartData, setChartData] = useState(null)

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const result = await axiosApi.get('/main_page/charts/');

                const chart = result.data;
                setChartData(chart)
                setFirstChart(chart[0] && chart[0]);
                setSecondChart(chart[1] && chart[1]);
                setThirdChart(chart[2] && chart[2]);
                setForthChart(chart[3] && chart[3]);

                setLoading(false)
            } catch (err) {
                setLoading(false)
            }
        };
        fetchData();
    }, [])


    const generateRandomColor = () => {
        const red = Math.floor(Math.random() * 256);
        const green = Math.floor(Math.random() * 256);
        const blue = Math.floor(Math.random() * 256);
        return `rgba(${red}, ${green}, ${blue}, 0.6)`;
    };


    const [data, setData] = useState({})
    const [secondData, setSecondData] = useState({});
    const [thirdData, setThirdData] = useState({});
    const [forthData, setForthData] = useState({});


    useEffect(() => {

        if (firstChart) {
            const innerArray = Object.keys(firstChart.json_data);

            const arr = firstChart.json_data[innerArray[0]];
            const finalArr = []
            let labelName = null;
            for (let i = 0; i < arr.length; i++) {
                const obj = arr[i];
                const keys = Object.keys(obj);
                if (keys.length <= 2) {
                    labelName = 'Кыргызская Республика'
                    const lastKey = keys[keys.length - 1];
                    // Get the value of the last key
                    const lastValue = obj[lastKey];
                    finalArr.push(lastValue);
                }

                for (let j = keys.length-1; j >=2 ; j--) {
                    if(obj[keys[j]] !== ''){
                        finalArr.push(obj[keys[j]]);
                        break;
                    }
                }

            }

            const dataSets = [];

            for (let i = 0; i < finalArr.length; i++) {
                const color = generateRandomColor();
                const dataSetObj = {
                    label: labelName ? labelName : finalArr[i],
                    data: Object.keys(firstChart.json_data).map((key) => {
                        const arr = firstChart.json_data[key]
                        return arr[i].Value
                    }),
                    backgroundColor: color,
                    borderColor: generateRandomColor(),
                    pointBorderColor: color,
                    tension: 0,

                }
                dataSets.push(dataSetObj)
            }

            const data = {
                labels: Object.keys(firstChart.json_data),
                datasets: dataSets.slice(0,10)

            }

            setData(data)
        }

        if (secondChart) {
            const innerArray = Object.keys(secondChart.json_data);

            const arr = secondChart.json_data[innerArray[0]];
            const finalArr = []
            let labelName = null;
            for (let i = 0; i < arr.length; i++) {
                const obj = arr[i];
                const keys = Object.keys(obj);
                if (keys.length <= 2) {
                    labelName = 'Кыргызская Республика'
                    const lastKey = keys[keys.length - 1];
                    // Get the value of the last key
                    const lastValue = obj[lastKey];
                    finalArr.push(lastValue);
                }

                for (let j = keys.length-1; j >=2 ; j--) {
                    if(obj[keys[j]] !== ''){
                        finalArr.push(obj[keys[j]]);
                        break;
                    }
                }

            }


            const dataSets = [];


            for (let i = 0; i < finalArr.length; i++) {
                const color = generateRandomColor();
                const dataSetObj = {
                    label: labelName ? labelName : (finalArr[i] === '' ? 'Все' : finalArr[i]),
                    data: Object.keys(secondChart.json_data).map((key) => {
                        const arr = secondChart.json_data[key]
                        return arr[i].Value
                    }),
                    backgroundColor: color,
                    borderColor: generateRandomColor(),
                    pointBorderColor: color,
                    tension: 0,
                }
                dataSets.push(dataSetObj)
            }

            const data = {
                labels: Object.keys(secondChart.json_data),
                datasets: dataSets.slice(0,10)

            }

            setSecondData(data)
        }

        if (thirdChart) {

            const innerArray = Object.keys(thirdChart.json_data);
            const arr = thirdChart.json_data[innerArray[0]];
            const finalArr = []
            let labelName = null;
            for (let i = 0; i < arr.length; i++) {
                const obj = arr[i];
                const keys = Object.keys(obj);
                if (keys.length <= 2) {
                    labelName = 'Кыргызская Республика'
                    const lastKey = keys[keys.length - 1];
                    // Get the value of the last key
                    const lastValue = obj[lastKey];
                    finalArr.push(lastValue);
                }

                for (let j = keys.length-1; j >=2 ; j--) {
                    if(obj[keys[j]] !== ''){
                        finalArr.push(obj[keys[j]]);
                        break;
                    }
                }

            }

            const dataSets = [];

            for (let i = 0; i < finalArr.length; i++) {
                const color = generateRandomColor();
                const dataSetObj = {
                    label: labelName ? labelName : (finalArr[i] === '' ? 'Все' : finalArr[i]),
                    data: Object.keys(thirdChart.json_data).map((key) => {
                        const arr = thirdChart.json_data[key]
                        return arr[i].Value
                    }),
                    backgroundColor: color,
                    borderColor: generateRandomColor(),
                    pointBorderColor: color,
                    tension: 0,
                    borderWidth: 1,
                }
                dataSets.push(dataSetObj)
            }

            const data = {
                labels: Object.keys(thirdChart.json_data),
                datasets: dataSets.slice(0,10)
            }
            setThirdData(data)
        }


        if (forthChart) {

            const innerArray = Object.keys(forthChart.json_data);
            const arr = forthChart.json_data[innerArray[0]];
            const finalArr = []
            let labelName = null;
            for (let i = 0; i < arr.length; i++) {
                const obj = arr[i];
                const keys = Object.keys(obj);

                if (keys.length <= 2) {
                    labelName = 'Кыргызская Республика'
                    const lastKey = keys[keys.length - 1];
                    // Get the value of the last key
                    if(lastKey !== 'COMPOSITE_BREAKDOWN'){
                        const lastValue = obj[lastKey];
                        finalArr.push(lastValue);
                    }

                }
                for (let j = keys.length-1; j >=2 ; j--) {
                    if(obj[keys[j]] !== ''){
                        finalArr.push(obj[keys[j]]);
                        break;
                    }
                }

            }

            const dataSets = [];

            for (let i = 0; i < finalArr.length; i++) {
                const color = generateRandomColor();
                const dataSetObj = {
                    label: labelName ? labelName : (finalArr[i] === '' ? 'Все' : finalArr[i]),
                    data: Object.keys(forthChart.json_data).map((key) => {
                        const arr = forthChart.json_data[key]
                        return arr[i].Value
                    }),
                    backgroundColor: color,
                    borderColor: generateRandomColor(),
                    pointBorderColor: color,
                    tension: 0,
                    borderWidth: 1,
                }
                dataSets.push(dataSetObj)
            }

            const data = {
                labels: Object.keys(forthChart.json_data),
                datasets: dataSets.slice(0,10)
            }

            setForthData(data)
        }

    }, [firstChart, secondChart, thirdChart, forthChart])


    return (
        <>
            {loading ? (
                <>
                </>
            ) : (
                <Container maxWidth={false} sx={{maxWidth: "1368px"}}>
                    <div className={"lib-home"}>
                        <Grid container justifyContent={"space-between"} alignItems={"center"}>
                            <Grid item>
                                <h4 className="block-title">{t('goalStatistics')}</h4>
                            </Grid>
                            <Grid item>
                                <a className="show-more-btn" href="https://sustainabledevelopment-kyrgyzstan.github.io/"
                                   target="_blank" rel="noopener">{t('more')}</a>
                            </Grid>
                        </Grid>
                        <div className="blue-line "></div>
                        <div className={"line"}></div>
                    </div>

                    <div className={"goals-swiper-container"}>
                        <Swiper
                            pagination={{
                                el: `#goals-big-swiper`,
                                type: "bullets",
                                clickable: false,
                            }}
                            modules={[Navigation, Pagination]}
                            className="mySwiper"
                            slidesPerView={2}
                            spaceBetween={10}
                            navigation={{
                                nextEl: ".swiper-button-next-goal",
                                prevEl: ".swiper-button-prev-goal",
                                clickable: true,
                            }}

                            breakpoints={{
                                // when window width is >= 320px
                                320: {
                                    slidesPerView: 1,
                                    spaceBetween: 1
                                },
                                // when window width is >= 480px
                                480: {
                                    slidesPerView: 1,
                                    spaceBetween: 1
                                },
                                // when window width is >= 740px
                                540: {
                                    slidesPerView: 1,
                                    spaceBetween: 1
                                },
                                740: {
                                    slidesPerView: 1,
                                    spaceBetween: 1
                                },

                                880: {
                                    slidesPerView: 2,
                                    spaceBetween: 10
                                },
                                1040: {
                                    slidesPerView: 2,
                                    spaceBetween: 10
                                },

                            }

                            }

                        >
                            {firstChart && (
                                <SwiperSlide>
                                    <ChartFromApi
                                        data={data}
                                        obj={firstChart}
                                        type={firstChart.chart_type}
                                        max={firstChart.max_value}/>
                                </SwiperSlide>
                            )}

                            {secondChart && (
                                <SwiperSlide>
                                    <ChartFromApi
                                        data={secondData}
                                        obj={secondChart}
                                        type={secondChart.chart_type}
                                        max={secondChart.max_value}
                                    />
                                </SwiperSlide>
                            )}

                            {thirdChart && (
                                <SwiperSlide>
                                    <ChartFromApi
                                        data={thirdData}
                                        obj={thirdChart}
                                        type={thirdChart.chart_type}
                                        max={thirdChart.max_value}
                                    />
                                </SwiperSlide>
                            )}

                            {forthChart && (
                                <SwiperSlide>
                                    <ChartFromApi
                                        data={forthData}
                                        obj={forthChart}
                                        type={forthChart.chart_type}
                                        max={forthChart.max_value}
                                    />
                                </SwiperSlide>
                            )}

                        </Swiper>


                        <div style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            margin: "15px 0"
                        }}>
                            <div className="swiper-pagination-container">
                                <div className="swiper-pagination" id={'goals-big-swiper'}
                                     style={{textAlign: "left"}}></div>
                            </div>

                        </div>
                        <div className={"navigation-btns-p"}>
                            <button className="swiper-button-prev-goal" style={{marginRight: "16px"}}><ArrowBackIosIcon
                                sx={{marginLeft: "8px"}}/></button>
                            <button className="swiper-button-next-goal"><ArrowForwardIosIcon/></button>
                        </div>

                    </div>

                    <div><Script src="https://e.infogram.com/js/dist/embed.js?40U">
                    </Script></div>

                </Container>
            )}
        </>

    );
};

export default GoalsSlider;

