import React from 'react';
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation, Pagination} from "swiper";
import ChartSingleCard from "../ChartSingleCard/ChartSingleCard";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import axiosApi from "../../axiosApi";
import ChartFromApi from "../ChartFromApi/ChartFromApi";

const GoalChartSlider = ({chartInfo}) => {
    const {t, i18n} = useTranslation();
    const [loading, setLoading] = useState(false);
    const [chartData, setChartData] = useState(null)
    const [firstChart, setFirstChart] = useState(null);
    const [secondChart, setSecondChart] = useState(null);
    const [thirdChart, setThirdChart] = useState(null);
    const [forthChart, setForthChart] = useState(null);


    useEffect(() => {

        setFirstChart(chartInfo[0] ? chartInfo[0] : null);
        setSecondChart(chartInfo[1] ? chartInfo[1] : null);
        setThirdChart(chartInfo[2] ? chartInfo[2] : null);
        setForthChart(chartInfo[3] ? chartInfo[3] : null);

    }, [chartInfo])


    const generateRandomColor = () => {
        const red = Math.floor(Math.random() * 256);
        const green = Math.floor(Math.random() * 256);
        const blue = Math.floor(Math.random() * 256);
        return `rgba(${red}, ${green}, ${blue}, 0.8)`;
    };

    const [data, setData] = useState(null)
    const [secondData, setSecondData] = useState(null);
    const [thirdData, setThirdData] = useState(null);
    const [forthData, setForthData] = useState(null);


    useEffect(() => {

        if (firstChart && firstChart.json_data) {
            const innerArray = Object.keys(firstChart.json_data);

            const arr = firstChart.json_data[innerArray[0]];
            const finalArr = []
            let labelName = null;
            for (let i = 0; i < arr.length; i++) {
                const obj = arr[i];
                const keys = Object.keys(obj);
                if (keys.length <= 2) {
                    labelName = 'Кыргызская Республика'
                    const lastKey = keys[keys.length - 1];
                    // Get the value of the last key
                    const lastValue = obj[lastKey];
                    finalArr.push(lastValue);
                }

                for (let j = keys.length - 1; j >= 2; j--) {
                    if (obj[keys[j]] !== '') {
                        finalArr.push(obj[keys[j]]);
                        break;
                    }
                }

            }

            const dataSets = [];

            for (let i = 0; i < finalArr.length; i++) {
                const color = generateRandomColor();
                const dataSetObj = {
                    label: labelName ? labelName : finalArr[i],
                    data: Object.keys(firstChart.json_data).map((key) => {
                        const arr = firstChart.json_data[key]
                        return arr[i].Value
                    }),
                    backgroundColor: color,
                    borderColor: generateRandomColor(),
                    pointBorderColor: color,
                    tension: 0,

                }
                dataSets.push(dataSetObj)
            }

            const data = {
                labels: Object.keys(firstChart.json_data),
                datasets: dataSets.slice(0, 10)

            }


            setData(data)
        }

        if (secondChart && secondChart.json_data) {
            const innerArray = Object.keys(secondChart.json_data);

            const arr = secondChart.json_data[innerArray[0]];
            const finalArr = []
            let labelName = null;
            for (let i = 0; i < arr.length; i++) {
                const obj = arr[i];
                const keys = Object.keys(obj);
                if (keys.length <= 2) {
                    labelName = 'Кыргызская Республика'
                    const lastKey = keys[keys.length - 1];
                    // Get the value of the last key
                    const lastValue = obj[lastKey];
                    finalArr.push(lastValue);
                }

                for (let j = keys.length - 1; j >= 2; j--) {
                    if (obj[keys[j]] !== '') {
                        finalArr.push(obj[keys[j]]);
                        break;
                    }
                }

            }


            const dataSets = [];


            for (let i = 0; i < finalArr.length; i++) {
                const color = generateRandomColor();
                const dataSetObj = {
                    label: labelName ? labelName : (finalArr[i] === '' ? 'Все' : finalArr[i]),
                    data: Object.keys(secondChart.json_data).map((key) => {
                        const arr = secondChart.json_data[key]
                        return arr[i].Value
                    }),
                    backgroundColor: color,
                    borderColor: generateRandomColor(),
                    pointBorderColor: color,
                    tension: 0,
                }
                dataSets.push(dataSetObj)
            }

            const data = {
                labels: Object.keys(secondChart.json_data),
                datasets: dataSets.slice(0, 10)

            }

            setSecondData(data)
        }

        if (thirdChart && thirdChart.json_data) {

            const innerArray = Object.keys(thirdChart.json_data);
            const arr = thirdChart.json_data[innerArray[0]];
            const finalArr = []
            let labelName = null;
            for (let i = 0; i < arr.length; i++) {
                const obj = arr[i];
                const keys = Object.keys(obj);
                if (keys.length <= 2) {
                    labelName = 'Кыргызская Республика'
                    const lastKey = keys[keys.length - 1];
                    // Get the value of the last key
                    const lastValue = obj[lastKey];
                    finalArr.push(lastValue);
                }

                for (let j = keys.length - 1; j >= 2; j--) {
                    if (obj[keys[j]] !== '') {
                        finalArr.push(obj[keys[j]]);
                        break;
                    }
                }

            }

            const dataSets = [];

            for (let i = 0; i < finalArr.length; i++) {
                const color = generateRandomColor();
                const dataSetObj = {
                    label: labelName ? labelName : (finalArr[i] === '' ? 'Все' : finalArr[i]),
                    data: Object.keys(thirdChart.json_data).map((key) => {
                        const arr = thirdChart.json_data[key]
                        return arr[i].Value
                    }),
                    backgroundColor: color,
                    borderColor: generateRandomColor(),
                    pointBorderColor: color,
                    tension: 0,
                    borderWidth: 1,
                }
                dataSets.push(dataSetObj)
            }

            const data = {
                labels: Object.keys(thirdChart.json_data),
                datasets: dataSets.slice(0, 10)
            }
            setThirdData(data)
        }


        if (forthChart && forthChart.json_data) {

            const innerArray = Object.keys(forthChart.json_data);
            const arr = forthChart.json_data[innerArray[0]];
            const finalArr = []
            let labelName = null;
            for (let i = 0; i < arr.length; i++) {
                const obj = arr[i];
                const keys = Object.keys(obj);

                if (keys.length <= 2) {
                    labelName = 'Кыргызская Республика'
                    const lastKey = keys[keys.length - 1];
                    // Get the value of the last key
                    if (lastKey !== 'COMPOSITE_BREAKDOWN') {
                        const lastValue = obj[lastKey];
                        finalArr.push(lastValue);
                    }

                }
                for (let j = keys.length - 1; j >= 2; j--) {
                    if (obj[keys[j]] !== '') {
                        finalArr.push(obj[keys[j]]);
                        break;
                    }
                }

            }

            const dataSets = [];

            for (let i = 0; i < finalArr.length; i++) {
                const color = generateRandomColor();
                const dataSetObj = {
                    label: labelName ? labelName : (finalArr[i] === '' ? 'Все' : finalArr[i]),
                    data: Object.keys(forthChart.json_data).map((key) => {
                        const arr = forthChart.json_data[key]
                        return arr[i].Value
                    }),
                    backgroundColor: color,
                    borderColor: generateRandomColor(),
                    pointBorderColor: color,
                    tension: 0,
                    borderWidth: 1,
                }
                dataSets.push(dataSetObj)
            }

            const data = {
                labels: Object.keys(forthChart.json_data),
                datasets: dataSets.slice(0, 10)
            }

            setForthData(data)
        }

    }, [firstChart, secondChart, thirdChart, forthChart])


    return (
        <>{chartInfo.length > 0 && (
            <div className={"goals-swiper-container"}>
                <Swiper
                    pagination={{
                        el: `#goal-big-swiper`,
                        type: "bullets",
                        clickable: false,
                    }}
                    modules={[Navigation, Pagination]}
                    className="mySwiper"
                    slidesPerView={2}
                    spaceBetween={10}
                    navigation={{
                        nextEl: ".swiper-button-next-goal",
                        prevEl: ".swiper-button-prev-goal",
                        clickable: true,
                    }}
                >
                    {firstChart && data && (
                        <SwiperSlide>
                            <ChartFromApi
                                data={data}
                                obj={firstChart}
                                type={firstChart.chart_type}
                                max={firstChart.max_value}

                            />
                        </SwiperSlide>
                    )}

                    {secondChart && secondData && (
                        <SwiperSlide>
                            <ChartFromApi
                                data={secondData}
                                obj={secondChart}
                                type={secondChart.chart_type}
                                max={secondChart.max_value}
                            />
                        </SwiperSlide>
                    )}

                    {thirdChart && thirdData && (
                        <SwiperSlide>
                            <ChartFromApi
                                data={thirdData}
                                obj={thirdChart}
                                type={thirdChart.chart_type}
                                max={thirdChart.max_value}
                            />
                        </SwiperSlide>
                    )}

                    {forthChart && forthData && (
                        <SwiperSlide>
                            <ChartFromApi
                                data={forthData}
                                obj={forthChart}
                                type={forthChart.chart_type}
                                max={forthChart.max_value}
                            />
                        </SwiperSlide>
                    )}

                </Swiper>


                <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    margin: "15px 0"
                }}>
                    <div className="swiper-pagination-container">
                        <div className="swiper-pagination" id={'goal-big-swiper'}
                             style={{textAlign: "left"}}></div>
                    </div>

                </div>
                <div className={chartInfo.length > 2 ? "navigation-btns-p" : "hide"}>
                    <button className="swiper-button-prev-goal" style={{marginRight: "16px"}}>
                        <ArrowBackIosIcon
                            sx={{marginLeft: "8px"}}/></button>
                    <button className="swiper-button-next-goal"><ArrowForwardIosIcon/></button>
                </div>


            </div>
        )}

        </>

    );
};

export default GoalChartSlider;