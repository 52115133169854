export const main = '/';
export const commission = '/commission';
export const news = '/news';
export const detailedNews = '/detailedNews';
export const events = '/events';
export const detailedEvent = '/detailedEvent';
export const library = '/library';
export const videos = '/videos';
export const resources = '/resources';
export const goal = '/goal';
export const organization = '/organization';







