import React, {useEffect} from 'react';
import {CssBaseline} from "@mui/material";
import AppToolBar from "../AppToolBar/AppToolBar";
import Footer from "../Footer/Footer";
import {useDispatch, useSelector} from "react-redux";
import SearchResults from "../../containers/SearchResults/SearchResults";
import {useLocation} from "react-router-dom";
import {clearSearch} from "../../store/actions/goalsActions";


const Layout = ({children}) => {
    const searchResults = useSelector(state => state.goals.mainSearch);
const dispatch = useDispatch();
    const location = useLocation();

    useEffect(()=>{
       dispatch(clearSearch())
    },[location.pathname])

    return (
        <>
            <CssBaseline/>
                <AppToolBar/>
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <div style={{flex: '1 1 auto'}}>
                    {Object.keys(searchResults).length > 0 ? (
                        <SearchResults/>
                    ) : (
                        <>
                            {children}
                        </>
                    )}

                </div>
            </div>
            <Footer/>
        </>
    );
};

export default Layout;