import React from 'react';
import './styles.css';
import {Container, Grid} from "@mui/material";
import emblem from '../../assets/images/emblem.svg';
const LinksBlock = () => {
    return (
        <div className="links-block">
            <Container maxWidth={false} sx={{maxWidth: "1368px"}}>

                <Grid container justifyContent={"space-between"} sx={{backgroundColor:" #00A4DC"}}>
                    <Grid item xs={5.8} sm={3.8} md={2} sx={{marginBottom:"5px"}}>
                        <div className="link-box">
                            <img src={emblem} alt="emblem"/>
                            <p>Министерство культуры, информации, спорта и молодежной политики Кыргызской Республики</p>
                        </div>
                    </Grid>
                    <Grid item xs={5.8} sm={3.8} md={2} sx={{marginBottom:"5px"}}>
                        <div className="link-box">
                            <img src={emblem} alt="emblem"/>
                            <p>Министерство культуры, информации, спорта и молодежной политики Кыргызской Республики</p>
                        </div>
                    </Grid>
                    <Grid item xs={5.8} sm={3.8} md={2} sx={{marginBottom:"5px"}}>
                        <div className="link-box">
                            <img src={emblem} alt="emblem"/>
                            <p>Министерство культуры, информации, спорта и молодежной политики Кыргызской Республики</p>
                        </div>
                    </Grid>
                    <Grid item xs={5.8} sm={3.8} md={2} sx={{marginBottom:"5px"}}>
                        <div className="link-box">
                            <img src={emblem} alt="emblem"/>
                            <p>Министерство культуры, информации, спорта и молодежной политики Кыргызской Республики</p>
                        </div>
                    </Grid>
                    <Grid item xs={5.8} sm={3.8} md={2} sx={{marginBottom:"5px"}}>
                        <div className="link-box">
                            <img src={emblem} alt="emblem"/>
                            <p>Министерство культуры, информации, спорта и молодежной политики Кыргызской Республики</p>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};

export default LinksBlock;