import * as React from 'react';
import {useEffect, useState} from 'react';
import {AppBar, Box, Button, Container, FormControl, MenuItem, Select, Toolbar, useMediaQuery} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import logo from "../../assets/images/SDG_logo.svg";
import './styles.css'
import {Link, useLocation, useNavigate} from "react-router-dom";
import {commission, events, library, main, news, resources, videos} from "../../paths";
import {changeLanguage} from "../../i18next";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SearchIcon from '../../assets/images/Search.svg';
import CloseIcon from '@mui/icons-material/Close';
import {useTranslation} from "react-i18next";
import enLogo from '../../assets/images/EN.png';
import ruLogo from '../../assets/images/RU.png';
import kyLogo from '../../assets/images/KG.png';
import {KG, RU, US} from 'country-flag-icons/react/3x2'
import {useDispatch, useSelector} from "react-redux";
import {clearSearch, getSearchRequest} from "../../store/actions/goalsActions";
import {PulseLoader} from "react-spinners";


const AppToolBar = () => {
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let location = useLocation();
    let pathname = location.pathname;
    const isSmallScreen = useMediaQuery('(max-width:1000px)');
    const mobileScreen = useMediaQuery('(max-width:504px)');

    const [searchShow, setSearchShow] = useState(false)
    const loading = useSelector(state => state.goals.searchLoading);

    const pages = [
        {name: 'commission', route: commission},
        {name: 'news', route: news},
        {name: 'events', route: events},
        {name: 'library', route: library},
        {name: 'videos', route: videos},
        {name: 'resources', route: resources},
    ];


    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [language, setLanguage] = React.useState(sessionStorage.getItem('lng') || 'ky');

    const [searchParams, setSearchParams] = useState('')

    useEffect(() => {
        setSearchShow(false)
        setSearchParams('')
    }, [location.pathname])

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const onChange = (lng) => {
        changeLanguage(lng)
    }

    const handleLanguageChange = (event) => {
        setLanguage(event.target.value);
    };

    const onSearchSubmit = (e) => {
        e.preventDefault();
        dispatch(getSearchRequest(searchParams))
    }


    return (
        <AppBar position="static" elevation={15}
                style={{
                    backgroundColor: "white",
                    padding: "5px 0",
                    boxShadow: "0px 8px 9px -5px rgb(0 0 0 / 0%), 0px 15px 22px 2px rgb(0 0 0 / 5%), 0px 6px 28px 5px rgb(0 0 0 / 0%)"
                }}
        >
            <Container maxWidth={false} sx={{maxWidth: "1368px"}}>
                <Toolbar disableGutters={true}>
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            display: isSmallScreen ? 'none' : "flex",
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                            width: "8%",
                            margin: "0",
                            height: "78px"
                        }}
                    >
                        <img style={{width: "65px", minHeight: "57px"}}
                             src={logo} alt="codefish-logo"/>
                    </Typography>

                    <Box sx={{
                        flexGrow: 1,
                        display: isSmallScreen ? 'flex' : "none",
                        position: "relative",
                        zIndex: "77"
                    }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon sx={{color: "#00ADEF", fontSize: "35px"}}/>
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            inputProps={{MenuProps: {disableScrollLock: true}}}
                            disableScrollLock={true}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: {xs: 'block', md: 'none'},
                                width: "100%",
                            }}
                        >

                            {pages.map((page) => (
                                <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                                    <Typography
                                        textAlign="center"
                                        component={Link}
                                        to={page.route}
                                        sx={{
                                            textDecoration: 'none',
                                            color: '#81807F',
                                            fontFamily: 'Montserrat , sans-serif',
                                            fontWeight: "500",
                                            textTransform: "capitalize",
                                            fontSize: "14px",
                                            "&:hover": {
                                                color: "#00A4DC",
                                                backgroundColor: "transparent"
                                            }
                                        }}
                                        inputProps={{MenuProps: {disableScrollLock: true}}}
                                    >
                                        {t(page.name)}
                                    </Typography>
                                </MenuItem>
                            ))}
                        </Menu>

                    </Box>

                    {searchShow ? (
                        <div style={{height: "76px", display: "flex", justifyContent: "space-between", width: "100%"}}>
                            <form style={{width: "100%"}} onSubmit={(e) => onSearchSubmit(e)}>
                                <input
                                    type="text"
                                    className={"search-input"}
                                    placeholder={t('search')}
                                    autoFocus={true}
                                    value={searchParams}
                                    onChange={(e) => setSearchParams(e.target.value)}
                                />

                            </form>

                            {loading ? (
                                <div style={{alignSelf: "center", width: "50px"}}>

                                    <PulseLoader
                                        color={"#00A4DC"}
                                        loading={true}
                                        size={10}
                                        aria-label="Loading Spinner"
                                        data-testid="loader"
                                        // style={{
                                        //     borderColor:"red"
                                        // }}
                                    />
                                </div>
                            ) : (
                                <IconButton onClick={() => {
                                    setSearchShow(false);
                                    setSearchParams('');
                                    dispatch(clearSearch())
                                }}
                                            sx={{alignSelf: "center"}}
                                ><CloseIcon sx={{color: "#006EB5", fontSize: "30px"}}/></IconButton>
                            )}

                        </div>
                    ) : (
                        <>
                            <Typography
                                variant="h5"
                                noWrap
                                component="a"
                                href="/"
                                sx={{
                                    mr: 1,
                                    display: isSmallScreen ? 'flex' : "none",
                                    flexGrow: 1,
                                    letterSpacing: '.3rem',
                                    color: 'inherit',
                                    textDecoration: 'none',
                                }}
                            >
                                <img style={{maxWidth: "65px", height: "78px"}}
                                     src={logo} alt="sdg-logo"/>
                            </Typography>
                            <Box sx={{
                                display: isSmallScreen ? 'none' : "flex",
                                justifyContent: "space-evenly",
                                flexGrow: "1",
                            }}>

                                {i18n.language === 'ky' ? (
                                    <>
                                        {pages.map(page => (
                                            <Button
                                                key={page.name}
                                                onClick={() => {
                                                    navigate(page.route)
                                                }}
                                                className={"navigation-btn"}
                                                sx={{
                                                    my: 2,
                                                    display: 'block',
                                                    color: page.route === pathname && page.route !== main ? "#00A4DC" : '#000000',
                                                    fontFamily: 'Montserrat , sans-serif',
                                                    fontWeight: "500",
                                                    fontSize: {md: "11px", lg: "14px"},
                                                    textTransform: "uppercase",
                                                    "&:hover": {
                                                        color: "#00A4DC",
                                                        backgroundColor: "transparent"
                                                    },

                                                }}
                                            >
                                                {t(page.name)}
                                            </Button>

                                        ))}
                                    </>
                                ) : (
                                    <>
                                        {pages.map(page => (
                                            <Button
                                                key={page.name}
                                                onClick={() => {
                                                    navigate(page.route)
                                                }}
                                                className={"navigation-btn"}
                                                sx={{
                                                    my: 2,
                                                    display: 'block',
                                                    color: page.route === pathname && page.route !== main ? "#00A4DC" : '#000000',
                                                    fontFamily: 'Montserrat , sans-serif',
                                                    fontWeight: "500",
                                                    fontSize: {md: "11px", lg: "14px"},
                                                    textTransform: "uppercase",
                                                    "&:hover": {
                                                        color: "#00A4DC",
                                                        backgroundColor: "transparent"
                                                    },
                                                    // "&.MuiButton-root":{
                                                    //     fontFamily: 'Solano Gothic Pro',
                                                    // }
                                                    // borderBottom: page.route === pathname ? '2px solid white' : 'none'
                                                }}
                                            >
                                                {t(page.name)}
                                            </Button>

                                        ))}
                                    </>
                                )}


                            </Box>
                            <Box className={'language-box'}>

                                <FormControl>
                                    <Select
                                        variant={"standard"}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        // defaultValue={'ky'}
                                        value={language}
                                        disableUnderline={true}
                                        // disableScrollLock={true}
                                        onChange={handleLanguageChange}
                                        IconComponent={KeyboardArrowDownIcon}
                                        sx={{
                                            boxShadow: 'none', backgroundColor: "transparent",
                                            '.MuiOutlinedInput-notchedOutline': {border: 0},
                                            color: "#006EB5",
                                            caretColor: "transparent",
                                            fontFamily: "Montserrat, sans-serif",
                                            fontSize: "13px",
                                            fontWeight: "500",
                                            textTransform: "uppercase",
                                            paddingLeft: "8px",
                                            paddingTop: "5px",
                                            '.Mui-select': {border: 0, backgroundColor: "transparent"},
                                            '.MuiSelect-select:focus': {border: 0, backgroundColor: "transparent"},
                                            '.Mui-focused': {border: 0, backgroundColor: "transparent"},
                                            '.MuiOutlinedInput-root': {border: 'none', backgroundColor: "transparent"},
                                            '.MuiSelect-icon': {
                                                color: '#006EB5',
                                                right: "0",
                                                top: "5px",
                                            },
                                            ".MuiSelect-outlined": {
                                                color: '#006EB5',
                                                backgroundColor: "transparent"
                                            }
                                        }}
                                        inputProps={{
                                            MenuProps: {
                                                disableScrollLock: true,
                                                sx: {
                                                    position: "absolute",
                                                    top: "0",
                                                    left: "0",
                                                    color: "#006EB5",
                                                    '& .MuiMenuItem-root': {
                                                        color: "#006EB5",
                                                        fontFamily: 'Montserrat, sans-serif'
                                                    },
                                                }
                                            }
                                        }}

                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    bgcolor: 'white',
                                                    color: "#006EB5",
                                                    '& .MuiMenuItem-root': {
                                                        color: "#006EB5",
                                                        fontFamily: 'Montserrat, sans-serif'
                                                    },
                                                    // '& .MuiMenuItem-root:hover': {
                                                    //     color: '#5695dc'
                                                    // }
                                                },

                                            },

                                        }}
                                    >
                                        <MenuItem
                                            value={'ky'}
                                            onClick={() => onChange('ky')}
                                        >
                                            <KG title="Kyrgyz Republic"
                                                style={{width: "25px", border: "1px solid lightgrey"}}/>
                                        </MenuItem>
                                        <MenuItem
                                            value={'ru'}
                                            onClick={() => onChange('ru')}
                                        >
                                            <RU title="Russia" style={{width: "25px", border: "1px solid lightgrey"}}/>
                                        </MenuItem>
                                        <MenuItem
                                            value={'en'}
                                            onClick={() => onChange('en')}
                                        >
                                            <US title="United States"
                                                style={{width: "25px", border: "1px solid lightgrey"}}/>
                                        </MenuItem>

                                    </Select>
                                </FormControl>
                                <div onClick={() => setSearchShow(!searchShow)}
                                     style={{display: "flex", alignItems: "center"}}><img src={SearchIcon} alt="search"
                                                                                          className={'search-icon'}/>
                                </div>
                                <Box className={'purple-logo'} style={{display: mobileScreen ? 'none' : "flex"}}>
                                    <a href={'https://economy.kg/'} target="_blank" rel="noopener" >
                                        {i18n.language === 'ky' && (
                                            <img src={kyLogo} alt="logo" style={{display: "block", height: "100%"}}/>
                                        )}

                                        {i18n.language === 'ru' && (
                                            <img src={ruLogo} alt="logo" style={{display: "block", height: "100%"}}/>
                                        )}

                                        {i18n.language === 'en' && (
                                            <img src={enLogo} alt="logo" style={{display: "block", height: "100%"}}/>
                                        )}
                                    </a>
                                </Box>
                            </Box>


                        </>
                    )}


                </Toolbar>


            </Container>
        </AppBar>
    );
};
export default AppToolBar;