import React from 'react';
import './styles.css';
import {useTranslation} from "react-i18next";
import {Grid} from "@mui/material";
import Icon1 from "../../assets/images/1icon.png";
import {mediaUrl} from "../../config";

const AimsComponent = ({goals}) => {
    const {t, i18n} = useTranslation();
    return (
        <Grid container>
            {goals.length >0 && goals.map(goal=>(
                <div className='aim'>
                    {i18n.language === 'ky' && (
                        <p>{goal.goal_number}&nbsp;Максат:&nbsp;{goal.title_ky}</p>
                    )}
                    {i18n.language === 'ru' && (
                        <p>Цель&nbsp;{goal.goal_number}:&nbsp;{goal.title_ru}</p>
                    )}
                    {i18n.language === 'en' && (
                        <p>Goal&nbsp;{goal.goal_number}:&nbsp;{goal.title_en}</p>
                    )}

                </div>
            ))}

            {goals.length >0 && goals.map(goal=>(
                <div><img src={mediaUrl+goal.little_icon} alt="goal" className="goal-icon"/></div>
            ))}
        </Grid>


    );
};

export default AimsComponent;