import React from 'react';
import './styles.css';
import mapImg from '../../assets/images/Background map.svg';
import arrowRight from '../../assets/images/arrow right.svg'

const Map = () => {
    return (
        <div className={"map-box"}>
            <h4>Доля населения, живущего за международной чертой бедности, в разбивке по территории</h4>
            <img src={mapImg} alt="mapImg" className={'mapImg'}/>

            <a href="https://sdg.kg/map/" target="_blank" rel="noopener noreferrer">Детали карты
                <img src={arrowRight} alt="arrowRight" className={"arrow-map-img"}/>
            </a>
        </div>
    );
};

export default Map;