import React from 'react';
import {Line} from 'react-chartjs-2';
import './styles.css';

import {
    Chart as ChartJS,
    LineElement,
    CategoryScale,//x axis,
    LinearScale, //y axis
    PointElement,
    Legend,
    Tooltip,
} from 'chart.js'
import {useTranslation} from "react-i18next";

ChartJS.register(
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Legend,
    Tooltip
)

const ChartSingleCard = ({type, dataInfo}) => {
        const {t, i18n} = useTranslation();

        let data = null;
        let max = 20;

        if (type === 'events') {
            data = {
                labels: dataInfo?.map(d => t('goal') + " " + d.goal_number) || [],
                datasets: [{
                    label: t('events'),
                    data: dataInfo?.map(d => d.events_count) || [],
                    backgroundColor: "#F2994A",
                    borderColor: "#F2994A",
                    pointBorderColor: "#F2994A",
                    tension: 0,
                }]
            }

            const maxEventsCountObj = dataInfo.reduce((acc,
                                                       obj) => obj.events_count > acc.events_count ? obj : acc, {events_count: 0});
            max = maxEventsCountObj.events_count + 10;


        }

        if (type === 'news') {
            data = {
                labels: dataInfo?.map(d => t('goal') + " " + d.goal_number) || [],
                datasets: [{
                    label: t('news'),
                    data: dataInfo?.map(d => d.news_count) || [],
                    backgroundColor: "#5282da",
                    borderColor: "#5282da",
                    pointBorderColor: "#5282da",
                    tension: 0,
                }]
            }

            const maxNewsCountObj = dataInfo.reduce((acc,
                                                     obj) => obj.news_count > acc.news_count ? obj : acc, {news_count: 0});
            max = maxNewsCountObj.news_count + 5;

        }


        if (type === 'library') {
            data = {
                labels: dataInfo?.map(d => t('goal') + " " + d.goal_number) || [],
                datasets: [{
                    label: t('library'),
                    data: dataInfo?.map(d => d.library_count) || [],
                    backgroundColor: "#115604",
                    borderColor: "#115604",
                    pointBorderColor: "#115604",
                    tension: 0,
                }]
            }

            const maxLibraryCountObj = dataInfo.reduce((acc,
                                                        obj) => obj.library_count > acc.library_count ? obj : acc, {library_count: 0});
            max = maxLibraryCountObj.library_count + 10;

        }


        const plugin = {
            beforeInit: function (chart) {
                // Get reference to the original fit function
                const originalFit = chart.legend.fit

                // Override the fit function
                chart.legend.fit = function fit() {
                    // Bind scope in order to use `this` correctly inside it
                    originalFit.bind(chart.legend)()
                    this.height += 35 // Change the height
                }
            }
        }


        const options = {
            plugins: {
                legend: {
                    labels: {
                        boxWidth: 18, // set the width of the colored rectangle
                        boxHeight: 2, // set the height of the colored rectangle
                        padding: 15, // set the padding around the colored rectangle
                        font: {
                            size: 14
                        },
                    },
                    position: 'top',
                    align: "end",
                },

                tooltip: {
                    mode: "nearest",
                    callbacks: {
                        label: (tooltipItem) => {
                            return tooltipItem.formattedValue; // show the formatted value of the data point as the label
                        },
                        title: (tooltipItems) => {
                            return tooltipItems[0].label; // show the label of the data point as the title
                        },
                    },
                },

            },

            scales: {
                y: {
                    min: 0,
                    max: max,
                },
            },
            maintainAspectRatio: false,
            responsive: true,
        }

        return (
            <div className={"chart-box"}>

                {type === 'news' && (
                    <>
                        {i18n.language === 'ky' && (
                            <p className="chart-title">Максаттар боюнча жаңылыктардын саны</p>
                        )}

                        {i18n.language === 'ru' && (
                            <p className="chart-title">Количество новостей по целям </p>
                        )}

                        {i18n.language === 'en' && (
                            <p className="chart-title">Number of news by goals</p>
                        )}

                    </>
                )}

                {type === 'events' && (
                    <>
                        {i18n.language === 'ky' && (
                            <p className="chart-title">Максаттар боюнча иш-чаралардын саны</p>
                        )}

                        {i18n.language === 'ru' && (
                            <p className="chart-title">Количество мероприятий по целям </p>
                        )}

                        {i18n.language === 'en' && (
                            <p className="chart-title">Number of events by goals</p>
                        )}

                    </>
                )}

                {type === 'library' && (
                    <>
                        {i18n.language === 'ky' && (
                            <p className="chart-title">Максаттар боюнча басылмалардын саны</p>
                        )}

                        {i18n.language === 'ru' && (
                            <p className="chart-title">Количество публикаций по целям </p>
                        )}

                        {i18n.language === 'en' && (
                            <p className="chart-title">Number of publications by goals</p>
                        )}

                    </>
                )}


                {data && (
                    <Line
                        data={data}
                        options={options}
                        plugins={[plugin]}
                    >

                    </Line>
                )}

            </div>
        );
    }
;

export default ChartSingleCard;