import React, {useEffect, useMemo, useState} from 'react';
import {Container, Grid, useMediaQuery} from "@mui/material";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation, Pagination} from "swiper";
import ChartSingleCard from "../../components/ChartSingleCard/ChartSingleCard";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import mapImg from '../../assets/images/Background map.svg';
import './styles.css';
import NewsFive from "../../components/NewsFive/NewsFive";
import LibrarySingleCard from "../../components/LibrarySingleCard/LibrarySingleCard";
import EventSingleCard from "../../components/EventSingleCard/EventSingleCard";
import SinglePlayer from "../../components/SinglePlayer/SInglePlayer";
import {Link, useNavigate, useParams} from "react-router-dom";
import {events} from "../../paths";
import axiosApi from "../../axiosApi";
import Preloader from "../../components/Preloader/Preloader";
import {useTranslation} from "react-i18next";
import {mediaUrl} from "../../config";
import DOMPurify from "dompurify";
import GoalChartSlider from "../../components/GoalChartSlider/GoalChartSlider";


const Goal = () => {
    const navigate = useNavigate();
    const {t, i18n} = useTranslation();
    const mobileScreen = useMediaQuery('(max-width:504px)');
    const [goalInfo, setGoalInfo] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const {id} = useParams();
    const isMobile = useMediaQuery('(max-width: 600px)');

    const [bishkek, setBishkek] = useState([]);
    const [oshCity, setOshCity] = useState([]);
    const [osh, setOsh] = useState([]);
    const [chui, setChui] = useState([]);
    const [talas, setTalas] = useState([]);
    const [naryn, setNaryn] = useState([]);
    const [jalalAbad, setJalalAbad] = useState([]);
    const [issyk, setIssyk] = useState([]);
    const [batken, setBatken] = useState([]);

    const [showPop, setShowPop] = useState(null);


    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);

            try {
                const result = await axiosApi.get('/goals/info/' + id + '/');
                setGoalInfo(result.data);


                const bishkek = await axiosApi.get('/ymap/region-info/1/?goal_id=' + id);
                setBishkek(bishkek.data)

                const oshCity = await axiosApi.get('/ymap/region-info/2/?goal_id=' + id);
                setOshCity(oshCity.data)

                const osh = await axiosApi.get('/ymap/region-info/3/?goal_id=' + id);
                setOsh(osh.data)

                const chui = await axiosApi.get('/ymap/region-info/4/?goal_id=' + id);
                setChui(chui.data)

                const talas = await axiosApi.get('/ymap/region-info/5/?goal_id=' + id);
                setTalas(talas.data)

                const naryn = await axiosApi.get('/ymap/region-info/6/?goal_id=' + id);
                setNaryn(naryn.data)

                const jalal = await axiosApi.get('/ymap/region-info/7/?goal_id=' + id);
                setJalalAbad(jalal.data)

                const issyk = await axiosApi.get('/ymap/region-info/8/?goal_id=' + id);
                setIssyk(issyk.data)

                const batken = await axiosApi.get('/ymap/region-info/9/?goal_id=' + id);
                setBatken(batken.data)


                setLoading(false);


            } catch (err) {
                setError(err);
                setLoading(false)
            }
        };
        fetchData();

    }, [id])


    const sumCount = () => {
        const sum = bishkek.sum + oshCity.sum + osh.sum + chui.sum + talas.sum + naryn.sum + jalalAbad.sum + issyk.sum + batken.sum
        return sum.toLocaleString();
    }

    const countAmount = () => {
        const a = 'num_of_projects';
        const amount = bishkek[a] + oshCity[a] + osh[a] + chui[a] + talas[a] + naryn[a] + jalalAbad[a] + issyk[a] + batken[a];
        return amount
    }

    const breadcrumbs = useMemo(() => {
        return (
            <p className="path-text">
                <Link to={'/'}>{t('main')}</Link> /
                <span> {t('goal')} {goalInfo.goal_number}</span>
            </p>
        )

    }, [i18n.language, goalInfo])

    const hasJsonDataNotNull = goalInfo.statistics && goalInfo.statistics.some(obj => obj.json_data !== null);

    return (
        <>
            {loading ? (
                <Preloader/>
            ) : (
                <>{Object.keys(goalInfo).length > 0 && (
                    <Container maxWidth={false} sx={{maxWidth: "1368px"}}>
                        <Grid container justifyContent={"space-between"} alignItems={"center"}
                              sx={{marginTop: mobileScreen ? "20px" : "52px"}}>
                            <Grid item>
                                {i18n.language === 'ky' && (
                                    <h4 className="block-title">{goalInfo.goal_number} -
                                        Максат: &nbsp;{goalInfo.title_ky}</h4>
                                )}
                                {i18n.language === 'ru' && (
                                    <h4 className="block-title">Цель {goalInfo.goal_number}:&nbsp;{goalInfo.title_ru}</h4>
                                )}
                                {i18n.language === 'en' && (
                                    <h4 className="block-title">Goal {goalInfo.goal_number}:&nbsp;{goalInfo.title_en}</h4>
                                )}

                            </Grid>
                            {/*<Grid item>*/}
                            {/*    <button className={"show-more-btn"}>Показать все</button>*/}
                            {/*</Grid>*/}

                        </Grid>
                        <div className="blue-line goal-blue-line"></div>
                        <div className={"line"}></div>

                        {breadcrumbs}

                        <div style={{caretColor: "transparent", marginBottom: "45px"}}>
                            <div className={"slider-float-box"}>
                                <img src={mediaUrl + goalInfo.little_icon} alt="goal" className='goal-img'/>
                            </div>

                            {i18n.language === 'ky' && (
                                <div className={"goal-description"}
                                     dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(goalInfo.text_ky)}}>
                                </div>
                            )}
                            {i18n.language === 'ru' && (
                                <div className={"goal-description"}
                                     dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(goalInfo.text_ru)}}>
                                </div>
                            )}
                            {i18n.language === 'en' && (
                                <div className={"goal-description"}
                                     dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(goalInfo.text_en)}}>
                                </div>
                            )}

                        </div>
                        {goalInfo.statistics.length > 0 && hasJsonDataNotNull && (
                            <>
                                <div className={"lib-home"}>
                                    <Grid container justifyContent={"space-between"} alignItems={"center"}>
                                        <Grid item>
                                            {i18n.language === 'ky' && (
                                                <h4 className="block-title">{goalInfo.goal_number}&nbsp;{t('goalStatistics')}</h4>
                                            )}
                                            {i18n.language === 'ru' && (
                                                <h4 className="block-title">{t('goalStatistics')} &nbsp;{goalInfo.goal_number}</h4>
                                            )}
                                            {i18n.language === 'en' && (
                                                <h4 className="block-title">{goalInfo.goal_number}&nbsp;{t('goalStatistics')}</h4>
                                            )}
                                        </Grid>
                                    </Grid>
                                    <div className="blue-line "></div>
                                    <div className={"line"}></div>
                                </div>

                                <GoalChartSlider chartInfo={goalInfo.statistics}/>
                            </>
                        )}

                        <div className={"lib-home"}>
                            <Grid container justifyContent={"space-between"} alignItems={"center"}>
                                <Grid item>
                                    {i18n.language === 'ky' && (
                                        <h4 className="block-title">{goalInfo.goal_number}&nbsp;{t('projects')}</h4>
                                    )}
                                    {i18n.language === 'ru' && (
                                        <h4 className="block-title">{t('projects')}&nbsp;{goalInfo.goal_number}</h4>
                                    )}
                                    {i18n.language === 'en' && (
                                        <h4 className="block-title">{goalInfo.goal_number}&nbsp;{t('projects')}</h4>
                                    )}
                                </Grid>
                            </Grid>
                            <div className="blue-line "></div>
                            <div className={"line"}></div>
                        </div>

                        {isMobile ? (
                            <>
                                <div className={'projects'}>
                                    {i18n.language === 'ru' && (
                                        <p>{bishkek.title_ru}</p>
                                    )}
                                    {i18n.language === 'ky' && (
                                        <p>{bishkek.title_ky}</p>
                                    )}
                                    {i18n.language === 'en' && (
                                        <p>{bishkek.title_en}</p>
                                    )}
                                    <p>{t('amountOfProject')}: {bishkek.num_of_projects}</p>
                                    <p>{t('sum')}: {bishkek.sum.toLocaleString()} USD</p>

                                </div>
                                <div className='projects'>
                                    {i18n.language === 'ru' && (
                                        <p>{oshCity.title_ru}</p>
                                    )}
                                    {i18n.language === 'ky' && (
                                        <p>{oshCity.title_ky}</p>
                                    )}
                                    {i18n.language === 'en' && (
                                        <p>{oshCity.title_en}</p>
                                    )}
                                    <p>{t('amountOfProject')}: {oshCity.num_of_projects}</p>
                                    <p>{t('sum')}: {oshCity.sum.toLocaleString()} USD</p>

                                </div>

                                <div className='projects'>
                                    {i18n.language === 'ru' && (
                                        <p>{chui.title_ru}</p>
                                    )}
                                    {i18n.language === 'ky' && (
                                        <p>{chui.title_ky}</p>
                                    )}
                                    {i18n.language === 'en' && (
                                        <p>{chui.title_en}</p>
                                    )}
                                    <p>{t('amountOfProject')}: {chui.num_of_projects}</p>
                                    <p>{t('sum')}: {chui.sum.toLocaleString()} USD</p>

                                </div>
                                <div className='projects'>
                                    {i18n.language === 'ru' && (
                                        <p>{talas.title_ru}</p>
                                    )}
                                    {i18n.language === 'ky' && (
                                        <p>{talas.title_ky}</p>
                                    )}
                                    {i18n.language === 'en' && (
                                        <p>{talas.title_en}</p>
                                    )}
                                    <p>{t('amountOfProject')}: {talas.num_of_projects}</p>
                                    <p>{t('sum')}: {talas.sum.toLocaleString()} USD</p>

                                </div>
                                <div className='projects'>
                                    {i18n.language === 'ru' && (
                                        <p>{issyk.title_ru}</p>
                                    )}
                                    {i18n.language === 'ky' && (
                                        <p>{issyk.title_ky}</p>
                                    )}
                                    {i18n.language === 'en' && (
                                        <p>{issyk.title_en}</p>
                                    )}
                                    <p>{t('amountOfProject')}: {issyk.num_of_projects}</p>
                                    <p>{t('sum')}: {issyk.sum.toLocaleString()} USD</p>

                                </div>
                                <div className='projects'>
                                    {i18n.language === 'ru' && (
                                        <p>{naryn.title_ru}</p>
                                    )}
                                    {i18n.language === 'ky' && (
                                        <p>{naryn.title_ky}</p>
                                    )}
                                    {i18n.language === 'en' && (
                                        <p>{naryn.title_en}</p>
                                    )}
                                    <p>{t('amountOfProject')}: {naryn.num_of_projects}</p>
                                    <p>{t('sum')}: {naryn.sum.toLocaleString()} USD</p>

                                </div>
                                <div className='projects'>
                                    {i18n.language === 'ru' && (
                                        <p>{jalalAbad.title_ru}</p>
                                    )}
                                    {i18n.language === 'ky' && (
                                        <p>{jalalAbad.title_ky}</p>
                                    )}
                                    {i18n.language === 'en' && (
                                        <p>{jalalAbad.title_en}</p>
                                    )}
                                    <p>{t('amountOfProject')}: {jalalAbad.num_of_projects}</p>
                                    <p>{t('sum')}: {jalalAbad.sum.toLocaleString()} USD</p>

                                </div>
                                <div className='projects'>
                                    {i18n.language === 'ru' && (
                                        <p>{osh.title_ru}</p>
                                    )}
                                    {i18n.language === 'ky' && (
                                        <p>{osh.title_ky}</p>
                                    )}
                                    {i18n.language === 'en' && (
                                        <p>{osh.title_en}</p>
                                    )}
                                    <p>{t('amountOfProject')}: {osh.num_of_projects}</p>
                                    <p>{t('sum')}: {osh.sum.toLocaleString()} USD</p>

                                </div>
                                <div className='projects'>
                                    {i18n.language === 'ru' && (
                                        <p>{batken.title_ru}</p>
                                    )}
                                    {i18n.language === 'ky' && (
                                        <p>{batken.title_ky}</p>
                                    )}
                                    {i18n.language === 'en' && (
                                        <p>{batken.title_en}</p>
                                    )}
                                    <p>{t('amountOfProject')}: {batken.num_of_projects}</p>
                                    <p>{t('sum')}: {batken.sum.toLocaleString()} USD</p>

                                </div>
                                <div style={{marginTop: "10px"}}>

                                    <div className={'map-detail'}>
                                        <p>{t('amountOfProject')}</p>
                                        <b>{countAmount()}</b>
                                    </div>

                                    <div className={'map-detail'}>
                                        <p> {t('totalSum')}</p>
                                        <b> {sumCount()} USD</b>
                                    </div>

                                </div>

                            </>

                        ) : (
                            <div className='map'>
                                <div className='map-img-wrapper'>
                                    <img src={mapImg} alt="mapImg" className={'map-img'}/>
                                    <div className={'circle-pin'} id='bishkek'
                                         onMouseEnter={() => setShowPop(bishkek)}
                                         onMouseLeave={() => setShowPop(null)}
                                    >
                                        {bishkek?.num_of_projects}
                                    </div>
                                    <div className={'circle-pin'} id='oshCity'
                                         onMouseEnter={() => setShowPop(oshCity)}
                                         onMouseLeave={() => setShowPop(null)}
                                    >
                                        {oshCity?.num_of_projects}
                                    </div>
                                    <div className={'circle-pin'} id='chui'
                                         onMouseEnter={() => setShowPop(chui)}
                                         onMouseLeave={() => setShowPop(null)}
                                    >
                                        {chui?.num_of_projects}
                                    </div>
                                    <div className={'circle-pin'} id='kol'
                                         onMouseEnter={() => setShowPop(issyk)}
                                         onMouseLeave={() => setShowPop(null)}
                                    >
                                        {issyk?.num_of_projects}
                                    </div>
                                    <div className={'circle-pin'} id="naryn"
                                         onMouseEnter={() => setShowPop(naryn)}
                                         onMouseLeave={() => setShowPop(null)}
                                    >
                                        {naryn?.num_of_projects}
                                    </div>
                                    <div className={'circle-pin'} id="osh"
                                         onMouseEnter={() => setShowPop(osh)}
                                         onMouseLeave={() => setShowPop(null)}
                                    >
                                        {osh?.num_of_projects}
                                    </div>
                                    <div className={'circle-pin'} id="batken"
                                         onMouseEnter={() => setShowPop(batken)}
                                         onMouseLeave={() => setShowPop(null)}
                                    >
                                        {batken?.num_of_projects}
                                    </div>
                                    <div className={'circle-pin'} id="jalal"
                                         onMouseEnter={() => setShowPop(jalalAbad)}
                                         onMouseLeave={() => setShowPop(null)}
                                    >
                                        {jalalAbad?.num_of_projects}
                                    </div>
                                    <div className={'circle-pin'} id='talas'
                                         onMouseEnter={() => setShowPop(talas)}
                                         onMouseLeave={() => setShowPop(null)}
                                    >
                                        {talas?.num_of_projects}
                                    </div>


                                </div>

                                {showPop && (
                                    <div className={'popup'}>
                                        {i18n.language === 'ru' && (
                                            <p>{showPop.title_ru}</p>
                                        )}
                                        {i18n.language === 'ky' && (
                                            <p>{showPop.title_ky}</p>
                                        )}
                                        {i18n.language === 'en' && (
                                            <p>{showPop.title_en}</p>
                                        )}
                                        <p>{t('amountOfProject')}: {showPop.num_of_projects}</p>
                                        <p>{t('sum')}: {showPop.sum.toLocaleString()} USD</p>

                                    </div>
                                )}
                                <div className='map-details'>
                                    <div className={'map-detail'}>
                                        <p>{t('amountOfProject')}</p>
                                        <b>{countAmount()}</b>
                                    </div>

                                    <div className={'map-detail'}>
                                        <p> {t('totalSum')}</p>
                                        <b> {sumCount()} USD</b>
                                    </div>
                                </div>

                            </div>
                        )}


                        {goalInfo.articles_gov_goal.length > 0 && (
                            <div>
                                <div className={"lib-home"}>
                                    <Grid container justifyContent={"space-between"} alignItems={"center"}>
                                        <Grid item>
                                            <h4 className="block-title">{t('government')}</h4>
                                        </Grid>
                                    </Grid>
                                    <div className="blue-line "></div>
                                    <div className={"line"}></div>
                                </div>

                                <div className={"ministry-swiper-container"}>
                                    <Swiper
                                        pagination={{
                                            el: `#ministry-big-swiper`,
                                            type: "bullets",
                                            clickable: false,
                                        }}
                                        modules={[Navigation, Pagination]}
                                        className="mySwiper"
                                        slidesPerView={3}
                                        spaceBetween={15}
                                        navigation={{
                                            nextEl: ".swiper-button-next-ministry",
                                            prevEl: ".swiper-button-prev-ministry",
                                            clickable: true,
                                        }}
                                        breakpoints={{
                                            // when window width is >= 320px
                                            320: {
                                                slidesPerView: 1,
                                                spaceBetween: 1
                                            },

                                            740: {
                                                slidesPerView: 2,
                                                spaceBetween: 10
                                            },

                                            880: {
                                                slidesPerView: 2,
                                                spaceBetween: 10
                                            },
                                            1040: {
                                                slidesPerView: 3,
                                                spaceBetween: 15
                                            },

                                        }}
                                    >
                                        {goalInfo.articles_gov_goal.length > 0 && goalInfo.articles_gov_goal.map(org => (
                                            <SwiperSlide key={org.id}>
                                                <div
                                                    className={"international-box"}
                                                    onClick={() => navigate('/government/' + org.id)}
                                                    style={{backgroundImage: `url(${mediaUrl + org.government_agency_images[0]?.image})`}}
                                                >

                                                    {i18n.language === 'ky' && (
                                                        <h4>{org.title_ky}</h4>
                                                    )}

                                                    {i18n.language === 'ru' && (
                                                        <h4>{org.title_ru}</h4>
                                                    )}

                                                    {i18n.language === 'en' && (
                                                        <h4>{org.title_en}</h4>
                                                    )}

                                                    {i18n.language === 'ky' && (
                                                        <h3>{org.gov_title_ky}</h3>
                                                    )}
                                                    {i18n.language === 'ru' && (
                                                        <h3>{org.gov_title_ru}</h3>
                                                    )}
                                                    {i18n.language === 'en' && (
                                                        <h3>{org.gov_title_en}</h3>
                                                    )}

                                                </div>
                                            </SwiperSlide>
                                        ))
                                        }
                                    </Swiper>


                                    {goalInfo.articles_gov_goal.length > 3 ? (
                                        <div style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            margin: "15px 0"
                                        }}>
                                            <div className="swiper-pagination-container">
                                                <div className="swiper-pagination" id={'ministry-big-swiper'}
                                                     style={{textAlign: "left"}}></div>
                                            </div>

                                        </div>
                                    ) : (
                                        <div style={{marginTop: "20px"}}></div>
                                    )}

                                    <div
                                        className={goalInfo.articles_gov_goal.length > 3 ? "navigation-btns-p" : "hide"}>
                                        <button className="swiper-button-prev-ministry" style={{marginRight: "16px"}}>
                                            <ArrowBackIosIcon
                                                sx={{marginLeft: "8px"}}/></button>
                                        <button className="swiper-button-next-ministry"><ArrowForwardIosIcon/></button>
                                    </div>


                                </div>

                            </div>
                        )}

                        {goalInfo.articles_int_goal.length > 0 && (
                            <div className="international-organizations">
                                <div className={"lib-home"}>
                                    <Grid container justifyContent={"space-between"} alignItems={"center"}>
                                        <Grid item>
                                            <h4 className="block-title">{t('international')}</h4>
                                        </Grid>
                                    </Grid>
                                    <div className="blue-line "></div>
                                    <div className={"line"}></div>
                                </div>

                                <div className={"international-swiper-container"}>
                                    <Swiper
                                        pagination={{
                                            el: `#international-big-swiper`,
                                            type: "bullets",
                                            clickable: false,
                                        }}
                                        modules={[Navigation, Pagination]}
                                        className="mySwiper"
                                        slidesPerView={3}
                                        spaceBetween={15}
                                        navigation={{
                                            nextEl: ".swiper-button-next-international",
                                            prevEl: ".swiper-button-prev-international",
                                            clickable: true,
                                        }}
                                        breakpoints={{
                                            // when window width is >= 320px
                                            320: {
                                                slidesPerView: 1,
                                                spaceBetween: 1
                                            },

                                            740: {
                                                slidesPerView: 2,
                                                spaceBetween: 10
                                            },

                                            880: {
                                                slidesPerView: 2,
                                                spaceBetween: 10
                                            },
                                            1040: {
                                                slidesPerView: 3,
                                                spaceBetween: 15
                                            },

                                        }}
                                    >

                                        {goalInfo.articles_int_goal.length > 0 && goalInfo.articles_int_goal.map(org => (
                                            <SwiperSlide key={org.id}>
                                                <div
                                                    className={"international-box"}
                                                    onClick={() => navigate('/organizations/' + org.id)}
                                                    style={{backgroundImage: `url(${mediaUrl + org.international_agency_images[0]?.image})`}}
                                                >

                                                    {i18n.language === 'ky' && (
                                                        <h4>{org.title_ky}</h4>
                                                    )}

                                                    {i18n.language === 'ru' && (
                                                        <h4>{org.title_ru}</h4>
                                                    )}

                                                    {i18n.language === 'en' && (
                                                        <h4>{org.title_en}</h4>
                                                    )}


                                                    {i18n.language === 'ky' && (
                                                        <h3>{org.int_title_ky}</h3>
                                                    )}
                                                    {i18n.language === 'ru' && (
                                                        <h3>{org.int_title_ru}</h3>
                                                    )}
                                                    {i18n.language === 'en' && (
                                                        <h3>{org.int_title_en}</h3>
                                                    )}


                                                </div>
                                            </SwiperSlide>
                                        ))}

                                    </Swiper>

                                    {goalInfo.articles_int_goal.length > 3 ? (
                                        <div style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            margin: "15px 0"
                                        }}>
                                            <div className="swiper-pagination-container">
                                                <div className="swiper-pagination" id={'international-big-swiper'}
                                                     style={{textAlign: "left"}}></div>
                                            </div>

                                        </div>
                                    ) : (
                                        <div style={{marginTop: "20px"}}></div>
                                    )}

                                    <div
                                        className={goalInfo.articles_int_goal.length > 3 ? "navigation-btns-p" : "hide"}>
                                        <button className="swiper-button-prev-international"
                                                style={{marginRight: "16px"}}>
                                            <ArrowBackIosIcon sx={{marginLeft: "8px"}}/></button>
                                        <button className="swiper-button-next-international"><ArrowForwardIosIcon/>
                                        </button>
                                    </div>
                                </div>

                            </div>
                        )}

                        {goalInfo.last_news.length > 0 && (
                            <>
                                <div className={"lib-home"}>
                                    <Grid container justifyContent={"space-between"} alignItems={"center"}>
                                        <Grid item>
                                            <h4 className="block-title">{t('news')}</h4>
                                        </Grid>
                                    </Grid>
                                    <div className="blue-line "></div>
                                    <div className={"line"}></div>
                                </div>
                                <div style={{marginTop: "-16px"}}>
                                    <NewsFive news={goalInfo.last_news}/>
                                </div>
                            </>

                        )}


                        {goalInfo.last_research.length > 0 && (
                            <>
                                <div className={"lib-home"}>
                                    <Grid container justifyContent={"space-between"} alignItems={"center"}>
                                        <Grid item>
                                            <h4 className="block-title">{t('library')}</h4>
                                        </Grid>
                                    </Grid>
                                    <div className="blue-line "></div>
                                    <div className={"line"}></div>
                                </div>
                                <Grid container justifyContent={"space-between"}>
                                    {goalInfo.last_research.map(lib => (
                                        <Grid item xs={12} sm={6} md={3.3} key={lib.id}>
                                            <LibrarySingleCard content={lib}/>
                                        </Grid>
                                    ))}
                                </Grid>
                            </>
                        )}

                        {goalInfo.last_events.length > 0 && (
                            <div style={{marginBottom: "20px"}}>
                                <div className={"lib-home"}>
                                    <Grid container justifyContent={"space-between"} alignItems={"center"}>
                                        <Grid item>
                                            <h4 className="block-title">{t('events')}</h4>
                                        </Grid>
                                    </Grid>
                                    <div className="blue-line"></div>
                                    <div className={"line"}></div>
                                </div>
                                <Grid container
                                      justifyContent={goalInfo.last_events.length === 2 ? "left" : 'space-between'}
                                      spacing={goalInfo.last_events.length === 2 ? 2 : 0}>
                                    {goalInfo.last_events.map(event => (
                                        <Grid item xs={12} sm={5.85} md={3.85} lg={3.85} key={event.id}>
                                            <EventSingleCard content={event}/>
                                        </Grid>
                                    ))}
                                </Grid>

                            </div>
                        )}

                        {goalInfo.agency_goal_ids.length > 0 && (
                            <>
                                <div className={"lib-home"}>
                                    <Grid container justifyContent={"space-between"} alignItems={"center"}>
                                        <Grid item>
                                            <h4 className="block-title">{t('resources')}</h4>
                                        </Grid>
                                    </Grid>
                                    <div className="blue-line"></div>
                                    <div className={"line"}></div>
                                </div>

                                {goalInfo.agency_goal_ids.map(res => (
                                    <>
                                        <Grid container className={"resource-card"} justifyContent={"space-between"}
                                              alignItems={'center'} key={res.id}>
                                            <Grid item xs={12} sm={2} md={2} container
                                                  justifyContent={{xs: "left", sm: "space-between"}}
                                                  sx={{marginBottom: {xs: "20px", sm: "0"}}}>
                                                <img src={mediaUrl + res.icon} alt="emblem" className='emblem'/>
                                            </Grid>
                                            <Grid item xs={12} sm={5} md={5.5} container
                                                  justifyContent={{xs: "left", sm: "space-between"}}
                                                  sx={{marginBottom: {xs: "20px", sm: "0"}}} flexDirection={'column'}>

                                                {i18n.language === 'ky' && (
                                                    <p className={'resource-title'}>{res.title_ky}</p>
                                                )}
                                                {i18n.language === 'ru' && (
                                                    <p className={'resource-title'}>{res.title_ru}</p>
                                                )}

                                                {i18n.language === 'en' && (
                                                    <p className={'resource-title'}>{res.title_en}</p>
                                                )}


                                                <div className={"resource-card-inner"}>
                                                    {res.goal_ids.length > 0 && res.goal_ids.map(goal => (
                                                        <img src={mediaUrl + goal.little_icon} alt="goal"
                                                             key={goal.id}/>
                                                    ))}
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} sm={5} md={4.5} className="resource-contact" container
                                                  justifyContent={{xs: "left", sm: "center", md: "right"}}
                                                  sx={{marginBottom: {xs: "20px", sm: "0"}}} flexDirection={'column'}>
                                                <a href={res.link} target="_blank"
                                                   rel="noopener noreferrer">{res.link}</a>

                                                {i18n.language === 'ky' && (
                                                    <p>{res.address_ky}</p>
                                                )}
                                                {i18n.language === 'ru' && (
                                                    <p>{res.address_ru}</p>
                                                )}
                                                {i18n.language === 'en' && (
                                                    <p>{res.address_en}</p>
                                                )}
                                                <p>{t('phone')}: {res.phone}</p>
                                                <p>{t('email')}: {res.email}</p>
                                            </Grid>
                                        </Grid>

                                    </>
                                ))}


                            </>
                        )}

                        {goalInfo.last_video.length > 0 && (
                            <>
                                <div className={"lib-home"}>
                                    <Grid container justifyContent={"space-between"} alignItems={"center"}>
                                        <Grid item>
                                            <h4 className="block-title">{t('videos')}</h4>
                                        </Grid>
                                    </Grid>
                                    <div className="blue-line"></div>
                                    <div className={"line"}></div>
                                </div>
                                <Grid container justifyContent={"space-between"} sx={{margin: "0 0 55px"}}>

                                    {goalInfo.last_video.map(vid => (
                                        <Grid item xs={12} sm={5.85} md={3.85} key={vid.id}>
                                            <SinglePlayer video={vid}/>
                                        </Grid>
                                    ))}

                                </Grid>
                            </>
                        )}

                    </Container>
                )}

                </>

            )}
        </>

    );
};

export default Goal;