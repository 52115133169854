import React from 'react';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {Container, Grid} from "@mui/material";
import {mediaUrl} from "../../config";
import BigSlider from "../../components/BigSlider/BigSlider";
import NewsSingleCard from "../../components/NewsSingleCard/NewsSingleCard";
import EventSingleCard from "../../components/EventSingleCard/EventSingleCard";
import LibrarySingleCard from "../../components/LibrarySingleCard/LibrarySingleCard";
import SinglePlayer from "../../components/SinglePlayer/SInglePlayer";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation, Pagination} from "swiper";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const SearchResults = () => {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const searchResults = useSelector(state => state.goals.mainSearch);
    console.log(searchResults)


    return (
        <>
            {Object.keys(searchResults).length > 0 && (
                <Container maxWidth={false} sx={{maxWidth: "1368px", minHeight:"70vh", marginTop:"32px"}}>

                    {searchResults.goal.length > 0 && (
                        <>
                            <Grid container justifyContent={"space-between"} alignItems={"center"}>
                                <Grid item>
                                    <h4 className="block-title">{t('goalsSearch')}</h4>
                                </Grid>
                            </Grid>
                            <div className="blue-line lib-line"></div>
                            <div className={"line"}></div>
                            <div style={{marginBottom:"20px"}}></div>
                            <Grid container sx={{marginBottom:"20px"}}>
                                {searchResults.goal.map(goal => (
                                    <Grid
                                        item
                                        container
                                        xs={12} sm={6} md={3.8}
                                        alignItems={'center'}
                                        sx={{boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.1)", padding:"10px", caretColor:"transparent"}}
                                        onClick={()=>navigate('/goals/'+goal.id)}>
                                        <img src={mediaUrl + goal.little_icon} alt="goal" className='search-goal-ic'/>
                                        {i18n.language === 'ky' && (
                                            <h4 className="block-title">{goal.goal_number} -
                                                Максат: &nbsp;{goal.title_ky}</h4>
                                        )}
                                        {i18n.language === 'ru' && (
                                            <h4 className="block-title">Цель {goal.goal_number}:&nbsp;{goal.title_ru}</h4>
                                        )}
                                        {i18n.language === 'en' && (
                                            <h4 className="block-title">Goal {goal.goal_number}:&nbsp;{goal.title_en}</h4>
                                        )}
                                    </Grid>
                                ))}
                            </Grid>
                        </>
                    )}

                    {searchResults.news.length > 0 && (
                        <>
                            <Grid container justifyContent={"space-between"} alignItems={"center"}>
                                <Grid item>
                                    <h4 className="block-title">{t('news')}</h4>
                                </Grid>
                            </Grid>
                            <div className="blue-line lib-line"></div>
                            <div className={"line"}></div>
                            <div style={{marginBottom:"20px"}}></div>

                            {searchResults.news.length > 9 ? (
                                <BigSlider id={"search"} news={searchResults.news}/>
                            ):(
                                <Grid container justifyContent={"space-between"} sx={{margin: "32px 0"}}>
                                    <Grid item xs={12} md={3.2}>
                                        {searchResults.news[0] && (
                                            <NewsSingleCard
                                                news={searchResults.news[0]}
                                            />
                                        )}
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={7.5} lg={5}>
                                        {searchResults.news[1] && (
                                            <NewsSingleCard
                                                news={searchResults.news[1]}
                                            />
                                        )}
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={3.2}>
                                        {searchResults.news[2] && (
                                            <NewsSingleCard
                                                news={searchResults.news[2]}
                                            />
                                        )}
                                    </Grid>
                                    <Grid item sx={12} md={8.5}>
                                        {searchResults.news[3] && (
                                            <NewsSingleCard
                                                news={searchResults.news[3]}
                                            />
                                        )}
                                    </Grid>
                                    <Grid item md={3.2}>
                                        {searchResults.news[4] && (
                                            <NewsSingleCard
                                                news={searchResults.news[4]}
                                            />
                                        )}
                                    </Grid>
                                    <Grid item md={3.2}>
                                        {searchResults.news[5] && (
                                            <NewsSingleCard
                                                news={searchResults.news[5]}
                                            />
                                        )}
                                    </Grid>
                                    <Grid item md={5}>
                                        {searchResults.news[6] && (
                                            <NewsSingleCard
                                                news={searchResults.news[6]}
                                            />
                                        )}

                                    </Grid>
                                    <Grid item md={3.2}>
                                        {searchResults.news[7] && (
                                            <NewsSingleCard
                                                news={searchResults.news[7]}
                                            />
                                        )}
                                    </Grid>
                                    <Grid item md={8.5}>
                                        {searchResults.news[8] && (
                                            <NewsSingleCard
                                                news={searchResults.news[8]}
                                            />
                                        )}
                                    </Grid>
                                    <Grid item md={3.2}>
                                        {searchResults.news[9] && (
                                            <NewsSingleCard
                                                news={searchResults.news[9]}
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                            )}

                        </>

                    )}

                    {searchResults.events.length > 0 && (
                        <>
                            <Grid container justifyContent={"space-between"} alignItems={"center"}>
                                <Grid item>
                                    <h4 className="block-title">{t('events')}</h4>
                                </Grid>
                            </Grid>
                            <div className="blue-line lib-line"></div>
                            <div className={"line"}></div>
                            <div style={{marginBottom:"20px"}}></div>
                            <Grid container justifyContent={"space-between"} sx={{margin: "32px 0"}}>
                                {searchResults.events.map(event =>(
                                    <Grid item xs={12} sm={5.85} md={5.85} lg={3.85} key={event.id}>
                                        <EventSingleCard content={event}/>
                                    </Grid>
                                ))}
                            </Grid>
                        </>

                    )}

                    {searchResults.researchLibrary.length > 0 && (
                        <>
                            <Grid container justifyContent={"space-between"} alignItems={"center"}>
                                <Grid item>
                                    <h4 className="block-title">{t('research')}</h4>
                                </Grid>
                            </Grid>
                            <div className="blue-line lib-line"></div>
                            <div className={"line"}></div>
                            <div style={{marginBottom:"20px"}}></div>
                            <Grid container justifyContent={"space-between"} sx={{margin: "32px 0"}}>
                                {searchResults.researchLibrary.map(lib =>(
                                    <Grid item xs={12} sm={5.8} md={3.3} key={lib.id}>
                                        <LibrarySingleCard content={lib}/>
                                    </Grid>
                                ))}
                            </Grid>
                        </>

                    )}
                    {searchResults.nationalLibrary.length > 0 && (
                        <>
                            <Grid container justifyContent={"space-between"} alignItems={"center"}>
                                <Grid item>
                                    <h4 className="block-title">{t('national')} </h4>
                                </Grid>
                            </Grid>
                            <div className="blue-line lib-line"></div>
                            <div className={"line"}></div>
                            <div style={{marginBottom:"20px"}}></div>
                            <Grid container justifyContent={"space-between"} sx={{margin: "32px 0"}}>
                                {searchResults.nationalLibrary.map(lib =>(
                                    <Grid item xs={12} sm={5.8} md={3.3} key={lib.id}>
                                        <LibrarySingleCard content={lib}/>
                                    </Grid>
                                ))}
                            </Grid>
                        </>
                    )}
                    {searchResults.videos.length > 0 && (
                        <>
                            <Grid container justifyContent={"space-between"} alignItems={"center"}>
                                <Grid item>
                                    <h4 className="block-title">{t('videos')} </h4>
                                </Grid>
                            </Grid>
                            <div className="blue-line lib-line"></div>
                            <div className={"line"}></div>
                            <div style={{marginBottom:"20px"}}></div>
                            <Grid container justifyContent={"space-between"} sx={{margin: "32px 0"}}>
                                {searchResults.videos.map(video =>(
                                    <Grid item xs={12} sm={12} md={5.8} lg={3.85}>
                                            <SinglePlayer video={video}/>
                                    </Grid>
                                ))}
                            </Grid>
                        </>
                    )}

                    {searchResults.govArticle.length > 0 && (
                        <>
                            <Grid container justifyContent={"space-between"} alignItems={"center"}>
                                <Grid item>
                                    <h4 className="block-title">{t('government')} </h4>
                                </Grid>
                            </Grid>
                            <div className="blue-line lib-line"></div>
                            <div className={"line"}></div>
                            <div style={{marginBottom:"20px"}}></div>
                            <div className={"ministry-swiper-container"}>
                                <Swiper
                                    pagination={{
                                        el: `#ministry-big-swiper`,
                                        type: "bullets",
                                        clickable: false,
                                    }}
                                    modules={[Navigation, Pagination]}
                                    className="mySwiper"
                                    slidesPerView={3}
                                    spaceBetween={15}
                                    navigation={{
                                        nextEl: ".swiper-button-next-ministry",
                                        prevEl: ".swiper-button-prev-ministry",
                                        clickable: true,
                                    }}
                                    breakpoints={{
                                        // when window width is >= 320px
                                        320: {
                                            slidesPerView: 1,
                                            spaceBetween: 1
                                        },

                                        740: {
                                            slidesPerView: 2,
                                            spaceBetween: 10
                                        },

                                        880: {
                                            slidesPerView: 2,
                                            spaceBetween: 10
                                        },
                                        1040: {
                                            slidesPerView: 3,
                                            spaceBetween: 15
                                        },

                                    }}
                                >
                                    {searchResults.govArticle.map(org => (
                                        <SwiperSlide key={org.id}>
                                            <div
                                                className={"international-box"}
                                                onClick={() => navigate('/government/' + org.id)}
                                                style={{backgroundImage: `url(${mediaUrl + org.government_agency_images[0]?.image})`}}
                                            >

                                                {i18n.language === 'ky' && (
                                                    <h4>{org.title_ky}</h4>
                                                )}

                                                {i18n.language === 'ru' && (
                                                    <h4>{org.title_ru}</h4>
                                                )}

                                                {i18n.language === 'en' && (
                                                    <h4>{org.title_en}</h4>
                                                )}

                                                {i18n.language === 'ky' && (
                                                    <h3>{org.gov_title_ky}</h3>
                                                )}
                                                {i18n.language === 'ru' && (
                                                    <h3>{org.gov_title_ru}</h3>
                                                )}
                                                {i18n.language === 'en' && (
                                                    <h3>{org.gov_title_en}</h3>
                                                )}

                                            </div>
                                        </SwiperSlide>
                                    ))
                                    }
                                </Swiper>


                                {searchResults.govArticle.length > 3 ? (
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        margin: "15px 0"
                                    }}>
                                        <div className="swiper-pagination-container">
                                            <div className="swiper-pagination" id={'ministry-big-swiper'}
                                                 style={{textAlign: "left"}}></div>
                                        </div>

                                    </div>
                                ) : (
                                    <div style={{marginTop: "20px"}}></div>
                                )}

                                <div
                                    className={searchResults.govArticle.length > 3 ? "navigation-btns-p" : "hide"}>
                                    <button className="swiper-button-prev-ministry" style={{marginRight: "16px"}}>
                                        <ArrowBackIosIcon
                                            sx={{marginLeft: "8px"}}/></button>
                                    <button className="swiper-button-next-ministry"><ArrowForwardIosIcon/></button>
                                </div>


                            </div>
                        </>
                    )}

                    {searchResults.intArticle.length > 0 && (
                        <>
                            <Grid container justifyContent={"space-between"} alignItems={"center"}>
                                <Grid item>
                                    <h4 className="block-title">{t('international')} </h4>
                                </Grid>
                            </Grid>
                            <div className="blue-line lib-line"></div>
                            <div className={"line"}></div>
                            <div style={{marginBottom:"20px"}}></div>
                            <div className={"ministry-swiper-container"}>
                                <Swiper
                                    pagination={{
                                        el: `#ministry-big-swiper`,
                                        type: "bullets",
                                        clickable: false,
                                    }}
                                    modules={[Navigation, Pagination]}
                                    className="mySwiper"
                                    slidesPerView={3}
                                    spaceBetween={15}
                                    navigation={{
                                        nextEl: ".swiper-button-next-ministry",
                                        prevEl: ".swiper-button-prev-ministry",
                                        clickable: true,
                                    }}
                                    breakpoints={{
                                        // when window width is >= 320px
                                        320: {
                                            slidesPerView: 1,
                                            spaceBetween: 1
                                        },

                                        740: {
                                            slidesPerView: 2,
                                            spaceBetween: 10
                                        },

                                        880: {
                                            slidesPerView: 2,
                                            spaceBetween: 10
                                        },
                                        1040: {
                                            slidesPerView: 3,
                                            spaceBetween: 15
                                        },

                                    }}
                                >
                                    {searchResults.intArticle.map(org => (
                                        <SwiperSlide key={org.id}>
                                            <div
                                                className={"international-box"}
                                                onClick={() => navigate('/organizations/' + org.id)}
                                                style={{backgroundImage: `url(${mediaUrl + org.international_agency_images[0]?.image})`}}
                                            >

                                                {i18n.language === 'ky' && (
                                                    <h4>{org.title_ky}</h4>
                                                )}

                                                {i18n.language === 'ru' && (
                                                    <h4>{org.title_ru}</h4>
                                                )}

                                                {i18n.language === 'en' && (
                                                    <h4>{org.title_en}</h4>
                                                )}

                                                {i18n.language === 'ky' && (
                                                    <h3>{org.int_title_ky}</h3>
                                                )}
                                                {i18n.language === 'ru' && (
                                                    <h3>{org.int_title_ru}</h3>
                                                )}
                                                {i18n.language === 'en' && (
                                                    <h3>{org.int_title_en}</h3>
                                                )}

                                            </div>
                                        </SwiperSlide>
                                    ))
                                    }
                                </Swiper>


                                {searchResults.intArticle.length > 3 ? (
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        margin: "15px 0"
                                    }}>
                                        <div className="swiper-pagination-container">
                                            <div className="swiper-pagination" id={'international-big-swiper'}
                                                 style={{textAlign: "left"}}></div>
                                        </div>

                                    </div>
                                ) : (
                                    <div style={{marginTop: "20px"}}></div>
                                )}

                                <div
                                    className={searchResults.intArticle.length > 3 ? "navigation-btns-p" : "hide"}>
                                    <button className="swiper-button-prev-international"
                                            style={{marginRight: "16px"}}>
                                        <ArrowBackIosIcon sx={{marginLeft: "8px"}}/></button>
                                    <button className="swiper-button-next-international"><ArrowForwardIosIcon/>
                                    </button>
                                </div>


                            </div>
                        </>
                    )}

                    {searchResults.govAgency.length > 0 && (
                        <>
                            <Grid container justifyContent={"space-between"} alignItems={"center"}>
                                <Grid item>
                                    <h4 className="block-title">{t('government')} </h4>
                                </Grid>
                            </Grid>
                            <div className="blue-line lib-line"></div>
                            <div className={"line"}></div>
                            <div style={{marginBottom:"20px"}}></div>
                            <Grid container justifyContent={"space-between"} sx={{margin: "32px 0"}}>
                                {searchResults.govAgency.map(res =>(
                                    <Grid container className={"resource-card"} justifyContent={"space-between"}
                                          alignItems={'center'}>
                                        <Grid item xs={12} sm={2} md={2} container
                                              justifyContent={{xs: "center", sm: "space-between"}}
                                              sx={{marginBottom: {xs: "20px", sm: "0"}}}>
                                            <img src={mediaUrl + res.icon} alt="emblem" className='emblem'/>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={5.5} container
                                              justifyContent={{xs: "center", sm: "space-between"}}
                                              sx={{marginBottom: {xs: "20px", sm: "0"}}} flexDirection={'column'}>

                                            {i18n.language === 'ky' && (
                                                <p className={'resource-title'}>{res.title_ky}</p>
                                            )}
                                            {i18n.language === 'ru' && (
                                                <p className={'resource-title'}>{res.title_ru}</p>
                                            )}

                                            {i18n.language === 'en' && (
                                                <p className={'resource-title'}>{res.title_en}</p>
                                            )}


                                            <div className={"resource-card-inner"}>
                                                {res.goal_ids.length > 0 && res.goal_ids.map(goal => (
                                                    <img src={mediaUrl + goal.little_icon} alt="goal" key={goal.id}/>
                                                ))}
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={4} md={4.5} className="resource-contact" container
                                              justifyContent={{xs: "center", sm: "right"}}
                                              sx={{marginBottom: {xs: "20px", sm: "0"}}} flexDirection={'column'}>
                                            <a href={res.link} target="_blank" rel="noopener noreferrer">{res.link}</a>

                                            {i18n.language === 'ky' && (
                                                <p>{res.address_ky}</p>
                                            )}
                                            {i18n.language === 'ru' && (
                                                <p>{res.address_ru}</p>
                                            )}
                                            {i18n.language === 'en' && (
                                                <p>{res.address_en}</p>
                                            )}
                                            <p>{t('phone')}: {res.phone}</p>
                                            <p>{t('email')}: {res.email}</p>
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        </>
                    )}

                    {searchResults.intAgency.length > 0 && (
                        <>
                            <Grid container justifyContent={"space-between"} alignItems={"center"}>
                                <Grid item>
                                    <h4 className="block-title">{t('international')} </h4>
                                </Grid>
                            </Grid>
                            <div className="blue-line lib-line"></div>
                            <div className={"line"}></div>
                            <div style={{marginBottom:"20px"}}></div>
                            <Grid container justifyContent={"space-between"} sx={{margin: "32px 0"}}>
                                {searchResults.intAgency.map(res =>(
                                    <Grid container className={"resource-card"} justifyContent={"space-between"}
                                          alignItems={'center'}>
                                        <Grid item xs={12} sm={2} md={2} container
                                              justifyContent={{xs: "center", sm: "space-between"}}
                                              sx={{marginBottom: {xs: "20px", sm: "0"}}}>
                                            <img src={mediaUrl + res.icon} alt="emblem" className='emblem'/>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={5.5} container
                                              justifyContent={{xs: "center", sm: "space-between"}}
                                              sx={{marginBottom: {xs: "20px", sm: "0"}}} flexDirection={'column'}>

                                            {i18n.language === 'ky' && (
                                                <p className={'resource-title'}>{res.title_ky}</p>
                                            )}
                                            {i18n.language === 'ru' && (
                                                <p className={'resource-title'}>{res.title_ru}</p>
                                            )}

                                            {i18n.language === 'en' && (
                                                <p className={'resource-title'}>{res.title_en}</p>
                                            )}


                                            <div className={"resource-card-inner"}>
                                                {res.goal_ids.length > 0 && res.goal_ids.map(goal => (
                                                    <img src={mediaUrl + goal.little_icon} alt="goal" key={goal.id}/>
                                                ))}
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={4} md={4.5} className="resource-contact" container
                                              justifyContent={{xs: "center", sm: "right"}}
                                              sx={{marginBottom: {xs: "20px", sm: "0"}}} flexDirection={'column'}>
                                            <a href={res.link} target="_blank" rel="noopener noreferrer">{res.link}</a>

                                            {i18n.language === 'ky' && (
                                                <p>{res.address_ky}</p>
                                            )}
                                            {i18n.language === 'ru' && (
                                                <p>{res.address_ru}</p>
                                            )}
                                            {i18n.language === 'en' && (
                                                <p>{res.address_en}</p>
                                            )}
                                            <p>{t('phone')}: {res.phone}</p>
                                            <p>{t('email')}: {res.email}</p>
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        </>
                    )}

                    {searchResults.institutes.length > 0 && (
                        <>
                            <Grid container justifyContent={"space-between"} alignItems={"center"}>
                                <Grid item>
                                    <h4 className="block-title">{t('university')} </h4>
                                </Grid>
                            </Grid>
                            <div className="blue-line lib-line"></div>
                            <div className={"line"}></div>
                            <div style={{marginBottom:"20px"}}></div>
                            <Grid container justifyContent={"space-between"} sx={{margin: "32px 0"}}>
                                {searchResults.institutes.map(u=>(
                                    <div className={"uni-card"}>
                                        {i18n.language === 'ky' && (
                                            <h4>{u.title_ky}</h4>
                                        )}
                                        {i18n.language === 'ru' && (
                                            <h4>{u.title_ru}</h4>
                                        )}
                                        {i18n.language === 'en' && (
                                            <h4>{u.title_en}</h4>
                                        )}

                                        <a href="https://www.bhu.kg/">{u.link}</a>
                                    </div>
                                ))}
                            </Grid>
                        </>
                    )}

                </Container>
            )}

                </>
    );
};

export default SearchResults;