import React, {useEffect, useState} from 'react';
import './styles.css'
import {Container} from "@mui/system";
import {Grid} from "@mui/material";
import {useTranslation} from "react-i18next";
import axiosApi from "../../axiosApi";
import Preloader from "../Preloader/Preloader";
import {mediaUrl} from "../../config";
import {useDispatch, useSelector} from "react-redux";
import {fetchGoalsRequest} from "../../store/actions/goalsActions";
import {fetchNewsCategoryRequest} from "../../store/actions/newsActions";

const MainBlock = () => {
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false)
    const [fistSixGoals, setFirstSixGoals] = useState([])
    const [nextFiveGoals, setNextFiveGoals] = useState([])
    const [lastSixGoals, setLastSixGoals] = useState([])
    const [error, setError] = useState(null)


    const goals = useSelector(state => state.goals.goals);


    useEffect(() => {
        dispatch(fetchGoalsRequest())
        const fetchData = async () => {
            setLoading(true);
            try {
                const result = await axiosApi.get('/main_page/goals/');
                const sortedData = result.data.sort((a, b) => a.goal_number - b.goal_number);
                setFirstSixGoals(sortedData.slice(0, 6));
                setNextFiveGoals(sortedData.slice(6, 11));
                setLastSixGoals(sortedData.slice(11, 17));
                setLoading(false)
            } catch (err) {
                setError(err);
                setLoading(false)
            }
        };
        fetchData();

        dispatch(fetchNewsCategoryRequest());

    }, []);

    //
    // console.log("fistSixGoals", fistSixGoals)
    // console.log("nextFiveGoals", nextFiveGoals)
    // console.log("lastSixGoals", lastSixGoals)

    return (
        <div className={"main-block"}>
            <div className={"title-wrapper"}>
                <h3>{t('goals')}</h3>
            </div>
            {loading ? (
                <Preloader/>
            ) : (
                <Container maxWidth={false} sx={{maxWidth: "1368px"}}>

                    <Grid container className={"aims-box-parent"}>
                        {i18n.language === "ky" && (
                            <Grid item container justifyContent={"space-evenly"}>
                                {fistSixGoals.length > 0 && fistSixGoals.map((goal, i) => (
                                    <Grid item xs={4} sm={3} md={1.7} key={i}>
                                        <a href={'/goals/' + goal.id}> <img src={mediaUrl + goal.icon_ky}
                                                                            alt="aim"/></a>
                                    </Grid>
                                ))}

                            </Grid>
                        )}
                        {i18n.language === "ru" && (
                            <Grid item container justifyContent={"space-evenly"}>
                                {fistSixGoals.length > 0 && fistSixGoals.map((goal, i) => (
                                    <Grid item xs={4} sm={3} md={1.7} key={i}>
                                        <a href={'/goals/' + goal.id}> <img src={mediaUrl + goal.icon_ru}
                                                                            alt="aim"/></a>
                                    </Grid>
                                ))}

                            </Grid>
                        )}
                        {i18n.language === "en" && (
                            <Grid item container justifyContent={"space-evenly"}>
                                {fistSixGoals.length > 0 && fistSixGoals.map((goal, i) => (
                                    <Grid item xs={4} sm={3} md={1.7} key={i}>
                                        <a href={'/goals/' + goal.id}> <img src={mediaUrl + goal.icon_en}
                                                                            alt="aim"/></a>
                                    </Grid>
                                ))}

                            </Grid>
                        )}


                        <Grid item container justifyContent={"space-evenly"}
                              sx={{padding: {sm: "0", md: "0 90px"}, marginTop: {sm: "0", md: "-25px"}}}>
                            {nextFiveGoals.length > 0 && nextFiveGoals.map((goal, i) => (
                                <Grid item xs={4} sm={3} md={2} key={i}>
                                    {i18n.language === 'ky' && (
                                        <a href={'/goals/' + goal.id}> <img src={mediaUrl + goal.icon_ky}
                                                                            alt="aim"/></a>
                                    )}

                                    {i18n.language === 'ru' && (
                                        <a href={'/goals/' + goal.id}> <img src={mediaUrl + goal.icon_ru}
                                                                            alt="aim"/></a>
                                    )}

                                    {i18n.language === 'en' && (
                                        <a href={'/goals/' + goal.id}> <img src={mediaUrl + goal.icon_en}
                                                                            alt="aim"/></a>
                                    )}
                                </Grid>
                            ))}
                        </Grid>

                        <Grid item container justifyContent={"space-evenly"} sx={{marginTop: {sm: "0", md: "-25px"}}}>
                            {lastSixGoals.length > 0 && lastSixGoals.map((goal, i) => (
                                <Grid item xs={4} sm={3} md={1.7} key={i}>
                                    {i18n.language === 'ky' && (
                                        <a href={'/goals/' + goal.id}> <img src={mediaUrl + goal.icon_ky}
                                                                            alt="aim"/></a>
                                    )}

                                    {i18n.language === 'ru' && (
                                        <a href={'/goals/' + goal.id}> <img src={mediaUrl + goal.icon_ru}
                                                                            alt="aim"/></a>
                                    )}

                                    {i18n.language === 'en' && (
                                        <a href={'/goals/' + goal.id}> <img src={mediaUrl + goal.icon_en}
                                                                            alt="aim"/></a>
                                    )}
                                </Grid>
                            ))}

                        </Grid>

                    </Grid>
                </Container>
            )}

        </div>
    );
};

export default MainBlock;