import React, {useEffect, useState} from 'react';
import {Grid, Pagination} from "@mui/material";
import './styles.css';
import axiosApi from "../../axiosApi";
import {useTranslation} from "react-i18next";

const Universities = () => {
const {i18n}=useTranslation();
    const [loading, setLoading] = useState(false);
    const [univers, setUnivers] = useState([]);
    const [error, setError] = useState(null);
    const [pageCount, setPageCount] = useState(0)
    const [count, setCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(0);





    const getData = async (page) => {
        setCurrentPage(page)
        try {
            const result = await axiosApi.get('/library/institutes/?page=' + page);
            setUnivers(result.data && result.data.results);
            setLoading(false);

        } catch (error) {
            setError(error);
            setLoading(false);
        }
    }


    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const result = await axiosApi.get('/library/institutes/');
                setUnivers(result.data && result.data.results);
                setPageCount(Math.ceil(result.data.count / 7))
                setCount(result.data.count)
                setLoading(false);

            } catch (error) {
                setError(error);
                setLoading(false);
            }
        }

        fetchData();

    }, [])

    return (
        <Grid>
            <Grid container>

                {univers.length > 0 && univers.map(u=>(
                    <div className={"uni-card"}>
                        {i18n.language === 'ky' && (
                            <h4>{u.title_ky}</h4>
                        )}
                        {i18n.language === 'ru' && (
                            <h4>{u.title_ru}</h4>
                        )}
                        {i18n.language === 'en' && (
                            <h4>{u.title_en}</h4>
                        )}

                        <a href="https://www.bhu.kg/">{u.link}</a>
                    </div>
                ))}

            </Grid>
            {count && count > 7 && (
                <Grid container justifyContent={"right"} sx={{margin: '20px 0'}}>

                    <Pagination
                        count={pageCount}
                        onChange={(event, page) => getData(page)}
                        page={currentPage}
                        sx={{
                            '& .MuiPaginationItem-root': {
                                fontWeight: "600",
                                backgroundColor: "#F2F6F9",
                                borderRadius: "0",
                                color: "#55606E",
                                height: "38px"
                            },
                            '& .Mui-selected': {
                                border: ' 1px solid #00A4DC',
                                backgroundColor: "transparent",
                                color: "#00A4DC",
                                borderRadius: "0",
                                padding: "5px 0"
                            },
                            '& .MuiPaginationItem-root:hover': {
                                backgroundColor: "#00A4DC",
                                color: "white",
                            },
                            '.MuiPagination-ul': {
                                '& .MuiPaginationItem-icon': {
                                    color: '#00A4DC',
                                },
                                '& .MuiPaginationItem-icon:hover': {
                                    color: 'white',
                                },
                            },
                        }}
                    />
                </Grid>
            )}
        </Grid>
    );
};

export default Universities;