import React, {useEffect, useState} from 'react';
import './styles.css';
import {Container, Grid, MenuItem, Pagination, Select, useMediaQuery} from "@mui/material";
import Calendar from "../../components/Calendar/Calendar";
import LibrarySingleCard from "../../components/LibrarySingleCard/LibrarySingleCard";
import Universities from "../../components/Universities/Universities";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import axiosApi from "../../axiosApi";
import Preloader from "../../components/Preloader/Preloader";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {fetchGoalsRequest} from "../../store/actions/goalsActions";
import NotFound from "../../components/NotFound/NotFound";
import {resetDate} from "../../store/actions/newsActions";
import {useMemo} from "react";
import {Link} from "react-router-dom";

const Library = () => {
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const [tabId, setTabId] = useState('research');
    const [aim, setAim] = useState(1);
    const [aimId, setAimId] = useState(1);
    const mobileScreen = useMediaQuery('(max-width:504px)');

    const [loading, setLoading] = useState(false);
    const [libraries, setLibraries] = useState([]);
    const [error, setError] = useState(null);
    const [pageCount, setPageCount] = useState(0)
    const [count, setCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1);

    const goals = useSelector(state => state.goals.goals);
    const date = useSelector(state => state.news.date);

    const [nationals, setNationals] = useState('');

    console.log(libraries)

    const getData = async (page) => {
        setCurrentPage(page)
        if (date) {
            try {
                const selectedDate = new Date(date);
                const day = selectedDate.getDate();
                const month = selectedDate.getMonth() + 1;
                const year = selectedDate.getFullYear();
                const result = await axiosApi.get('/library/research/?page=' + page + '&goal_id=' + aimId + '&date=' + year + '-' + month + '-' + day);
                setLibraries(result.data && result.data.results);
                setLoading(false);

            } catch (error) {
                setError(error);
                setLoading(false);
            }
        } else {
            try {
                const result = await axiosApi.get('/library/research/?page=' + page + '&goal_id=' + aimId);
                setLibraries(result.data && result.data.results);
                setLoading(false);
            } catch (e) {
                setError(e);
                setLoading(false);
            }
        }
    }


    useEffect(() => {
        dispatch(fetchGoalsRequest());

        const getDate = async () => {
            try {
                const nationals = await axiosApi.get('/library/national/');
                setNationals(nationals.data.results)
            } catch (e) {
                console.log(e)
            }
        }

        getDate();

        return () => {
            dispatch(resetDate());
        }

    }, [])


    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);

            if (date) {
                const selectedDate = new Date(date);
                const year = selectedDate.getFullYear();
                const month = selectedDate.getMonth() + 1;
                const day = selectedDate.getDay();
                try {
                    const result = await axiosApi.get('/library/research/?goal_id=' + aimId + '&date=' + year + '-' + month + '-' + day);
                    setLibraries(result.data && result.data.results);
                    setPageCount(Math.ceil(result.data.count / 9))
                    setCount(result.data.count)
                    setLoading(false);

                } catch (error) {
                    setError(error);
                    setLoading(false);
                }
            } else {
                try {
                    const result = await axiosApi.get('/library/research/?page=1&goal_id=' + aimId);

                    setLibraries(result.data && result.data.results);
                    setPageCount(Math.ceil(result.data.count / 9))
                    setCount(result.data.count)
                    setLoading(false);

                } catch (error) {
                    setError(error);
                    setLoading(false);
                }
            }
        }

        fetchData();

    }, [date]);


    const getDataSearch = async (page) => {
        setCurrentPage(page)
        if (date) {
            try {
                const selectedDate = new Date(date);
                const day = selectedDate.getDate();
                const month = selectedDate.getMonth() + 1;
                const year = selectedDate.getFullYear();
                const result = await axiosApi.get('/library/research/?page=' + page + '&goal_id=' + aimId + '&date=' + year + '-' + month + '-' + day);
                setLibraries(result.data && result.data.results);
                setLoading(false);

            } catch (error) {
                setError(error);
                setLoading(false);
            }
        } else {
            try {
                const result = await axiosApi.get('/library/research/?page=' + page + '&goal_id=' + aimId);
                setLibraries(result.data && result.data.results);
                setLoading(false);
            } catch (e) {
                setError(e);
                setLoading(false);
            }
        }
    }


    const breadcrumbs = useMemo(() => {
        return (
            <p className="path-text">
                <Link  to={'/'}>{t('main')}</Link> /
                <Link to={'/news'}> {t('library')}</Link> /

                {tabId === 'research' && (
                    <span> {t('research')} </span>
                )}
                {tabId === 'university' && (
                    <span> {t('university')} </span>
                )}

            </p>
        )

    }, [i18n.language, tabId])


    return (
        <Container maxWidth={false} sx={{maxWidth: "1368px"}}>
            <Grid container justifyContent={"space-between"} alignItems={"center"}
                  sx={{marginTop: mobileScreen ? "20px" : "52px"}}>
                <Grid item>
                    <h4 className="block-title">{t('library')}</h4>
                </Grid>
                {/*<Grid item>*/}
                {/*    <button className={"show-more-btn"}>Показать все</button>*/}
                {/*</Grid>*/}

            </Grid>
            <div className="blue-line lib-line"></div>
            <div className={"line"}></div>

            {breadcrumbs}

            <Grid container className={"tabs-parent"}>
                <Grid item xs={12} sm={6} md={4} sx={{borderBottom: "2px solid #00A4DC",}}>
                    <div
                        className={`tabs-filter ${tabId === 'research' && 'active-tab'}`}
                        id={'research'}
                        onClick={(e) => setTabId(e.target.id)}
                        style={{textTransform: "uppercase"}}
                    >
                        {t('research')}
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4} sx={{borderBottom: "2px solid #00A4DC",}}>
                    <div
                        className={`tabs-filter`}
                        style={{textTransform: "uppercase"}}
                    >
                        {nationals && (
                            <a href={nationals[0].link}
                               target="_blank" rel="noopener noreferrer"
                               style={{textDecoration: "none", color: "#00A4DC"}}
                            >{t('national')}</a>
                        )}
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4} sx={{borderBottom: "2px solid #00A4DC",}}>
                    <div
                        className={`tabs-filter ${tabId === 'university' && 'active-tab'}`}
                        id={'university'}
                        onClick={(e) => setTabId(e.target.id)}
                    >
                        {t('university')}
                    </div>
                </Grid>
            </Grid>
            <div style={{marginBottom: "50px"}}></div>


            {tabId === 'university' ? (
                <div>
                    <Universities/>
                </div>

            ) : (

                <>{loading ? (
                    <Preloader/>
                ) : (
                    <>
                        <p className="filter-title">{t('filter')}</p>
                        <Grid container justifyContent={"space-between"} alignItems="center"
                              sx={{marginBottom: '66px'}}>
                            <Grid item xs={12} sm={8.9} sx={{marginBottom: "10px"}}>
                                <Select
                                    labelId="demo-select-small"
                                    id="demo-select-small"
                                    className={"aim-select"}
                                    value={aim}
                                    label={"format"}
                                    onChange={(e) => {
                                        const value = isNaN(e.target.value) ? "" : parseInt(e.target.value, 10);
                                        setAim(value);
                                    }}
                                    IconComponent={KeyboardArrowDownIcon}
                                    sx={{
                                        boxShadow: 'none',
                                        fontFamily: "Montserrat, sans-serif",
                                        backgroundColor: "rgba(255,255,255,0.7)",
                                        '.MuiSelect-select': {color: '#55606E',},
                                        borderRadius: "0px",
                                        '.MuiOutlinedInput-notchedOutline': {border: 0},
                                        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {border: 0},
                                        '.MuiSelect-icon': {
                                            color: '#006EB5',
                                            right: "9px",
                                            top: "25%",
                                        },

                                    }}


                                    inputProps={{
                                        MenuProps: {
                                            disableScrollLock: true,
                                            sx: {
                                                borderRadius: "0px",
                                                position: "absolute",
                                                top: "0",
                                                left: "0",
                                                '& .MuiMenuItem-root': {
                                                    fontFamily: 'Montserrat, sans-serif',
                                                    color: "#55606E",
                                                    whitespace: "pre-wrap"
                                                },
                                                '& .MuiMenuItem-root:hover': {
                                                    backgroundColor: "#d1e6ee",
                                                },
                                                '& .MuiList-root': {
                                                    padding: 0,
                                                }
                                            }
                                        }
                                    }}

                                >
                                    {goals.length > 0 && goals.map((goal) => (
                                        <MenuItem
                                            value={goal.goal_number}
                                            id={goal.id}
                                            onClick={() => setAimId(goal.id)}
                                            key={goal.id}
                                        >

                                            {i18n.language === 'ky' && (
                                                <p className={"goal"}><span
                                                    style={{color: "#00A4DC", marginRight: "10px"}}>{goal.goal_number} - Максат: </span>
                                                    {goal.title_ky}
                                                </p>
                                            )}
                                            {i18n.language === 'ru' && (
                                                <p className={"goal"}><span
                                                    style={{
                                                        color: "#00A4DC",
                                                        marginRight: "10px"
                                                    }}>Цель {goal.goal_number}: </span>
                                                    {goal.title_ru}
                                                </p>
                                            )}
                                            {i18n.language === 'en' && (
                                                <p className={"goal"}><span
                                                    style={{
                                                        color: "#00A4DC",
                                                        marginRight: "10px"
                                                    }}>Goal {goal.goal_number}: </span>
                                                    {goal.title_en}
                                                </p>
                                            )}

                                        </MenuItem>
                                    ))}

                                </Select>
                            </Grid>
                            <Grid item container xs={12} sm={3} justifyContent={{xs: "center", md: "right"}}
                                  sx={{marginBottom: "10px"}}>
                                <button
                                    className={'blue-main-btn search-btn'}
                                    onClick={() => getDataSearch(1)}>{t('search')}</button>
                            </Grid>
                        </Grid>

                        {libraries.length === 0 ? (
                            <Grid container justifyContent={"space-between"} sx={{margin: "32px 0"}}>
                                <Grid item md={3.2}>
                                    <Calendar/>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <NotFound/>
                                </Grid>
                            </Grid>


                        ) : (
                            <>
                                <Grid container justifyContent={"space-between"} sx={{margin: "32px 0"}}
                                      alignItems={"center"}>
                                    <Grid item xs={12} sm={5.8} md={3.2}>
                                        <Calendar/>
                                    </Grid>

                                    {libraries.map(lib => (
                                        <Grid item xs={12} sm={5.8} md={3.3} key={lib.id}>
                                        <LibrarySingleCard content={lib}/>
                                        </Grid>
                                        ))}


                                </Grid>
                                {count>8 && (
                                    <Grid container justifyContent={"right"} sx={{margin: '20px 0'}}>

                                        <Pagination
                                            count={pageCount}
                                            onChange={(event, page) => getData(page)}
                                            page={currentPage}
                                            sx={{
                                                '& .MuiPaginationItem-root': {
                                                    fontWeight: "600",
                                                    backgroundColor: "#F2F6F9",
                                                    borderRadius: "0",
                                                    color: "#55606E",
                                                    height: "38px"
                                                },
                                                '& .Mui-selected': {
                                                    border: ' 1px solid #00A4DC',
                                                    backgroundColor: "transparent",
                                                    color: "#00A4DC",
                                                    borderRadius: "0",
                                                    padding: "5px 0"
                                                },
                                                '& .MuiPaginationItem-root:hover': {
                                                    border: ' 1px solid #00A4DC',
                                                    color: "#00A4DC",
                                                },
                                                '.MuiPagination-ul': {
                                                    '& .MuiPaginationItem-icon': {
                                                        color: '#00A4DC',
                                                    },
                                                    '& .MuiPaginationItem-icon:hover': {
                                                        color: '#00A4DC',
                                                    },
                                                },
                                            }}
                                        />
                                    </Grid>
                                )}

                            </>

                        )}


                    </>
                )}
                </>
            )}

        </Container>
    );
};

export default Library;