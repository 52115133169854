import React from 'react';
import {Container, Grid} from "@mui/material";
import LibrarySingleCard from "../LibrarySingleCard/LibrarySingleCard";
import './styles.css'
import {useEffect, useState} from "react";
import axiosApi from "../../axiosApi";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {library} from "../../paths";

const LibraryHome = () => {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const [libraries, setLibraries] = useState([]);
    const [error, setError] = useState(null);

    useEffect(()=>{
        const fetchData = async () => {
            try {
                const result = await axiosApi.get('/main_page/library/');
                setLibraries(result.data);
            } catch (err) {
                setError(err);
            }
        };
        fetchData();
    },[])

    return (
        <Container maxWidth={false} sx={{maxWidth: "1368px"}}>
            <div className={"lib-home"}>
                <Grid container justifyContent={"space-between"} alignItems={"center"}>
                    <Grid item>
                        <h4 className="block-title">{t('library')}</h4>
                    </Grid>
                    <Grid item>
                        <button onClick={()=>navigate(library)} className={"show-more-btn"}>{t('seeAll')}</button>
                    </Grid>
                </Grid>
                <div className="blue-line lib-line"></div>
                <div className={"line"}></div>

                <Grid container justifyContent={{xs:"center", sm:"space-between"}} sx={{marginTop: "20px"}}>
                    {libraries.length > 0 && libraries.map(lib=>(
                        <Grid item xs={12} sm={5.8} md={3.3} key={lib.id}>
                            <LibrarySingleCard content={lib}/>
                        </Grid>
                    ))}
                </Grid>
            </div>

        </Container>
    );
};

export default LibraryHome;