import React from 'react';
import MainBlock from "../../components/MainBlock/MainBlock";
import NewsSlider from "../../components/NewsSlider/NewsSlider";
import LibraryHome from "../../components/LibraryHome/LibraryHome";
import LinksBlock from "../../components/LinksBlock/LinksBlock";
import GoalsSlider from "../../components/GoalsSlider/GoalsSlider";
import PlatformSlider from "../../components/PlatformSlider/PlatformSlider";
import './styles.css'

const Home = () => {
        return (
                    <div>
                        <MainBlock/>
                        <div style={{marginBottom: "32px"}}></div>
                        <GoalsSlider/>
                        <div style={{marginBottom: "32px"}}></div>
                        <PlatformSlider/>
                        <div style={{marginBottom: "32px"}}></div>
                        <NewsSlider/>
                        <LibraryHome/>
                        <LinksBlock/>
                    </div>
        );
    }
;

export default Home;